<!--
<image-selector :file.sync="file"></image-selector>
-->

<template>
  <div :style="{width: width}">
    <div class="image-selector" :style="{height: height}">
      <div v-if="title" class="head-title">{{title}}</div>
      <div class="preview" :style="{'background-image': `url(${imageUrl})`}">
        <span class="label" v-show="!imageUrl">{{label}}</span>
      </div>
      <div class="toolbar" :class="{'disabled': disabled}">
        <span @click="selectImage">选择</span>
        <span @click="removeImage">删除</span>
      </div>
    </div>
    <p v-if="description" style="font-size: 12px; color: #5c5c5c; margin-top: 10px; line-height: normal; text-align: center;">{{description}}</p>
  </div>
</template>

<script>
import selectFiles, { readDataUrlFromFile } from '@/utils/select-file'

export default {
  name: 'image-selector',
  props: {
    title: { type: String },
    disabled: { type: Boolean, default: false },
    description: { type: String },
    width: { type: String, default: '100%' },
    height: { type: String, default: '250px' },
    label: { type: String, default: '请选择图片' },
    file: { type: File },
    defaultImageUrl: { type: String, default: null }
  },
  computed: {
  },
  data () {
    return {
      imageUrl: this.defaultImageUrl
    }
  },
  watch: {
    file () {
      if (this.file) {
        readDataUrlFromFile(this.file, dataUrl => {
          this.imageUrl = dataUrl
        })
      } else {
        this.imageUrl = null
      }
    },
    defaultImageUrl (url) {
      if (!this.file) {
        this.imageUrl = url
      }
    },
    imageUrl () {
      this.$emit('imageUrlChanged', this.imageUrl)
    }
  },
  methods: {
    selectImage () {
      if (this.disabled) return
      selectFiles({
        accept: ['image/jpg', 'image/jpeg', 'image/png'],
        size: 1024 * 1024 * 2, // 2M
        multi: false,
        select: (files, e) => {
          if (e.length > 0) {
            this.$message.error('【' + e[0].file.name + '】' + e[0].message)
          } else if (files.length > 0) {
            this.$emit('update:file', files[0])
          }
        }
      })
    },
    removeImage () {
      if (this.disabled) return
      this.imageUrl = null
      this.$emit('update:file', null)
      this.$emit('removeFile')
    }
  }
}
</script>

<style lang="less" scoped>
@border: solid 1px #ddd;
.image-selector {
  display: flex;
  flex-direction: column;
  border: @border;
  border-radius: 2px;
  background-color: #fff;
  .head-title {
    text-align: center;
    padding: 10px 0;
    border-bottom: solid 1px #ddd;
    background-color: #f9f9f9;
  }
  .preview {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .label {
      font-size: 12px !important;
      color: #949494;
    }
  }
  .toolbar {
    height: 40px;
    line-height: 40px;
    border-top: @border;
    display: flex;
    span {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      flex: 1;
      cursor: pointer;
    }
    span + span {
      border-left: @border;
    }

    &.disabled span {
      color: #afafaf;
      cursor: not-allowed;
    }
  }
}
</style>
