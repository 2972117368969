<template>
  <div>
    <div style="padding: 0 60px;">
      客户登录服务
      <a-switch v-model="loginEnabled" @click="onSwitchClick()" checked-children="允许登录" un-checked-children="禁止登录"></a-switch>
    </div>
    <a-divider orientation="left">登录服务关闭后，允许的客户登录</a-divider>

    <ul class="list-group allow-customers">
      <li class="list-group-item">
        <div class="item-wrapper">
          <a-input v-model="phoneAdd"></a-input>
          <a-button type="primary" @click="addPhone" :disabled="!phoneAdd" :loading="saving" style="margin-left: 10px;">保存</a-button>
        </div>
      </li>
      <li class="list-group-item" v-for="phone in phones" :key="phone.id">
        <div class="item-wrapper">
          <div style="width: 195px;">{{phone.phone}}</div>
          <a-button type="danger" style="margin-left: 10px;" @click="removePhone(phone)">移除</a-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getCustomerLogin, updateCustomerLogin } from '@/http/api/config'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      loginEnabled: true,
      phones: [],
      saving: false,
      config: {
        disableLogin: false,
        allowCustomers: []
      },
      phoneAdd: null
    }
  },
  watch: {
  },
  methods: {
    show (firstShow) {
      if (firstShow) {
        this.loadData()
      }
    },
    hide () {
    },
    loadData () {
      this.dataLoading = false
      getCustomerLogin()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.config = resp.data
          this.loginEnabled = !this.config.disableLogin
          this.phones = this.config.allowCustomers.map(item => ({ id: item, phone: item }))
        })
        .send()
    },
    updateConfig () {
      this.config.disableLogin = !this.loginEnabled
      this.config.allowCustomers = this.phones.map(item => item.phone)
      this.saving = true
      updateCustomerLogin()
        .complete(() => (this.saving = false))
        .success(() => {
          this.loadData()
          this.$message.success('保存成功。')
        })
        .send(this.config)
    },
    onSwitchClick () {
      this.$nextTick(() => (this.updateConfig()))
    },
    addPhone () {
      this.phones.push({
        id: kit.str.id(),
        phone: this.phoneAdd
      })
      this.phoneAdd = null
      this.updateConfig()
    },
    removePhone (phone) {
      kit.arr.removeItem(this.phones, phone)
      this.updateConfig()
    }
  }
}
</script>

<style lang="less" scoped>
.allow-customers {
  margin-left: 60px;
  width: 300px;
  background-color: #fff;
  .item-wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
