<template>
  <modal ref="m"
         title="Prompt模版分组编辑器"
         @ok="onOk"
         @closed="onClosed"
         :loading="loading"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="标题" class="app_required-input" prop="title">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="单选或多选" prop="multiSelect">
        <a-switch v-model="form.multiSelect"></a-switch>
        <div class="text-muted font-mini">客户选择模版时，是否可以多选。</div>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="position">
        <a-input-number v-model="form.position" :min="1" :max="100" :step="1"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="是否可用" prop="enabled">
        <a-switch v-model="form.enabled"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type">
        <a-select v-model="form.type" style="width: 100%;">
          <a-select-option value="text">文字</a-select-option>
          <a-select-option value="textImage">文字+图片</a-select-option>
          <a-select-option value="lora">Lora模型</a-select-option>
          <a-select-option value="bg">背景风格</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </modal>
</template>

<script>
import { addTemplateGroup, updateTemplateGroup } from '@/http/api/prompt-template'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      loading: false,
      form: {
        title: null,
        multiSelect: false,
        enabled: true,
        position: 1,
        // text / textImage
        type: 'textImage'
      },
      model: null
    }
  },
  methods: {
    open (model) {
      this.$refs.m.open()
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
    },
    onClosed () {
      this.$refs.form.resetFields()
      this.model = null
    },
    onOk () {
      const api = this.model ? updateTemplateGroup : addTemplateGroup
      const data = Object.assign({}, this.form)
      if (this.model) {
        data.id = this.model.id
      }
      this.loading = true
      api()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
          this.$nextTick(() => (this.$refs.m.close()))
        })
        .send(data)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
