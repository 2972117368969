/**
 * @author jiangxingshang
 * @date 2022/12/7
 */
import kit from '@/utils/kit'
import ProtectResourceItemType from '@/constants/protect-resource-item-type'

export default class ProtectResourceUi {
  constructor (initData) {
    this.id = null
    this.title = null
    this.description = null
    this.code = null
    this.type = ProtectResourceItemType.mapping.menu

    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
