<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="手机">
            <a-input v-model="searchForm.phone" :allowClear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select style="width: 120px;" :allow-clear="true" v-model="searchForm.status">
              <a-select-option v-for="opt in $const.aiTaskExecImageStatus.options" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="任务名称">
            <a-input v-model="searchForm.title" :allowClear="true"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <div slot="center"  class="image-content">
      <div class="image-group" v-for="g in dataList" :key="g.id">
        <div class="group-title">Execution Id: {{g.id}} / 日期：{{g.createTime}}</div>
        <div class="group-title" v-if="g.img2ImgDto">
          <span v-if="g.img2ImgDto.prompt">正向提示词：{{g.img2ImgDto.prompt}} 负向提示词：{{g.img2ImgDto.negativePrompt}}</span>
          <span v-else-if="g.img2ImgDto.templateTitleList">模版：{{g.img2ImgDto.templateTitleList.join(' / ')}} / {{g.img2ImgDto.templatePrompt}}</span>
        </div>
        <div class="group-title" v-else-if="g.enlargeDto">
          <span v-if="g.enlargeDto.type === 'free'">
            Left: {{ g.enlargeDto.left }} Top: {{ g.enlargeDto.top }} Right: {{ g.enlargeDto.right }} Bottom: {{ g.enlargeDto.bottom }}}}
          </span>
          <span v-else>{{ g.enlargeDto.scales.join(' / ') }}</span>
        </div>
        <div class="image-list">
          <div class="image-wrapper" v-for="r in g.items" :key="r.id" @click="handlePreview(r, g)">
            <div class="number">#{{r.number}}</div>
            <div class="execution-time" v-if="r.status === 'completed' || r.status === 'filter'">
              耗时 {{Math.round(r.executionTime / 1000)}} 秒
            </div>

            <img :src="r.status === 'completed' || r.status === 'filter' ? r.imageFilter : r.image">

            <div class="status-wrapper" v-if="r.status !== 'completed'">
              <div class="status-msg fail-message" v-if="r.status === 'failed'">失败：{{r.statusMessage}}</div>
              <div class="status-msg fail-message" v-else-if="r.status === 'cancel'">用户取消</div>
              <div class="status-msg filter-message" v-else-if="r.status === 'filter'">{{r.statusMessage}}</div>
              <div class="status-msg queue-message" v-else-if="r.status === 'queue'">正在排队</div>
              <div class="status-msg in-progress" v-else-if="r.status === 'progress'"><a-icon type="loading" /> <span style="font-size: 12px;">AI运算中...</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>


    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="1200" :title="viewImageTitle">
      <div v-if="viewImageItem">
        <a-button type="link" @click="handleOpenLog(viewImageItem.id)">查看日志</a-button>
        <div class="image-2" v-if="viewImageItem.img2ImgDto">
          <img alt="mainImage"  :src="viewImageItem.img2ImgDto.image"/>
          <img alt="maskImage"  :src="viewImageItem.img2ImgDto.imageMask"/>
          <img alt="image" v-if="viewImageItem.imageFilter"  :src="viewImageItem.imageFilter"/>
        </div>
        <div class="image-2" v-else-if="viewImageItem.enlargeDto">
          <img alt="mainImage"  :src="viewImageItem.enlargeDto.image"/>
          <img alt="image" v-if="viewImageItem.imageFilter"  :src="viewImageItem.imageFilter"/>
        </div>
      </div>
    </a-modal>

    <a-modal :visible="detailModal.visible" :footer="null" @cancel="onDetailModalCancel" :width="700">
      <div>
        Request Body
        <a-textarea
            style="width: 100%;"
            v-model="detailModal.reqBody"
            :auto-size="{ minRows: 8, maxRows: 8 }"
        />
      </div>
      <div style="margin-top: 20px;">
        Response Body
        <a-textarea
            style="width: 100%;"
            v-model="detailModal.respBody"
            :auto-size="{ minRows: 8, maxRows: 8 }"
        />
      </div>
    </a-modal>
  </app-page>
</template>

<script>
import { ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { list } from '@/http/api/ai-task-exec-image'
import { getById as getAiLogById } from '@/http/api/ai-log'


export default {
  data () {
    return {
      routeName: ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE,
      dataList: [],
      dataLoading: false,
      previewVisible: false,
      viewImageItem: null,
      viewImageTitle: null,
      searchForm: {
        phone: null,
        status: '',
        title: ''
      },
      detailModal: {
        visible: false,
        reqBody: null,
        respBody: null
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const map = {}
          const groups = []
          for (const item of resp.data.records) {
            let g = map[item.exeId]
            if (g === undefined) {
              g = {
                id: item.exeId,
                img2ImgDto: item.img2ImgDto,
                enlargeDto: item.enlargeDto,
                items: [],
                createTime: item.createTime
              }
              map[g.id] = g
              groups.push(g)
            }
            if (item.img2ImgDto) {
              item.image = item.img2ImgDto.image
            } else if (item.enlargeDto) {
              item.image = item.enlargeDto.image
            }
            g.items.push(item)
          }
          this.dataList = groups
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handlePreview (r, g) {
      this.viewImageItem = r
      this.viewImageTitle = 'AI Task Execution Item ID: ' + r.id
      this.previewVisible = true
    },
    handleCancel () {
      this.previewVisible = false
    },
    onDetailModalCancel () {
      this.detailModal.visible = false
      this.detailModal.reqBody = null
      this.detailModal.respBody = null
    },
    handleOpenLog (id) {
      getAiLogById()
        .success(resp => {
          this.detailModal.visible = true
          this.detailModal.reqBody = resp.data.reqBody || '无日志'
          this.detailModal.respBody = resp.data.respBody || '无日志'
        })
        .send(id)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
  .image-content {
  }
  .image-group + .image-group{
    margin-top: 25px;
  }
  .image-group {
    .group-title {
      margin-bottom: 10px;
      font-size: 13px;
      color: #484747;
    }
    .image-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      .image-wrapper {
        position: relative;
        min-height: 100px;
        border: solid 1px #ddd;
        border-radius: 4px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
        .number {
          background-color: #6472a1;
          color: #fff;
          padding: 2px 10px;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
        }
        .execution-time {
          background-color: #ad5050;
          color: #fff;
          padding: 2px 10px;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
          right: 0;
          font-size: 12px;
        }
        .status-wrapper {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
          //background-color: rgba(0,0,0,.2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .status-msg {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          background-color: #fff;
          padding: 8px 20px;
          margin: 0 10px;
          border-radius: 15px;
        }
        .fail-message, .filter-message {
          color: #e82723;
        }
      }
    }
  }

  .image-2 {
    text-align: center;
    img {
      width: 330px;
      height: auto;
    }
    img + img {
      margin-left: 10px;
    }
  }
</style>
