<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="用户编辑"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="账号" class="app_required-input">
        <a-input v-model="form.username" :read-only="!isUsernameEditable"/>
      </a-form-model-item>
      <a-form-model-item label="密码" :class="{'app_required-input': !isUpdate}">
        <a-input v-model="form.password"/>
      </a-form-model-item>
      <a-form-model-item label="姓名" class="app_required-input">
        <a-input v-model="form.name"/>
      </a-form-model-item>
      <a-form-model-item label="是否可用">
        <a-switch v-model="form.enabled"></a-switch>
      </a-form-model-item>
    </a-form-model>

    <a-divider orientation="left">角色列表</a-divider>

    <a-empty v-show="roles.length === 0" description="还没有创建任何角色" />

    <ul class="list-group">
      <li class="list-group-item" v-for="r in roles" :key="r.id">
        <div class="left-right-content">
          <div class="full">
            <strong>{{r.name}}</strong>
            <p class="font-mini text-muted">{{r.description}}</p>
          </div>
          <div>
            <a-switch v-model="r.applied"></a-switch>
          </div>
        </div>
      </li>
    </ul>
  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import {
  addUser,
  updateUser,
  getUserRoles
} from '@/http/api/user'
import UserUpdateDto from '@/data/dto/user-update'
import CurrentUserRoleDto from '@/data/dto/current-user-role'
import UserModel from '@/data/model/user'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: new UserUpdateDto(),
      roles: [],
      qjList: []
    }
  },
  watch: {
    'form.id' (id) {
      if (id) {
      }
    }
  },
  computed: {
    isUpdate () {
      return !!this.form.id
    },
    isUsernameEditable () {
      return !this.isUpdate
    },
    appliedRoleIds () {
      const arr = []
      for (const r of this.roles) {
        if (r.applied) {
          arr.push(r.id)
        }
      }
      return arr
    }
  },
  methods: {
    open (model) {
      this.visible = true
      if (model) {
        this.form = new UserUpdateDto(model)
      }
      getUserRoles()
        .success(resp => {
          this.roles = kit.arr.newList(resp.data, CurrentUserRoleDto)
        })
        .send(model ? model.id : undefined)

    },
    onClose () {
      this.form = new UserUpdateDto()
      this.roles = []
    },
    onSave () {
      this.save()
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? updateUser() : addUser()
      this.form.appliedRoleIds = [...this.appliedRoleIds]
      api
        .complete((success) => {
          this.closable = true
          this.saveButtonLoading = false
          if (success) {
            this.$nextTick(() => (this.visible = false))
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', new UserModel(resp.data))
        })
        .send(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
.list-group-item p {
  margin-bottom: 0;
}
</style>
