import {
  ROUTE_USER,
  ROUTE_CUSTOMER,
  ROUTE_POINT_HISTORY,
  ROUTE_PROMPT_TEMPLATE,
  ROUTE_CONFIG,
  ROUTE_COMFY_UI,
  ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE,
  ROUTE_AI_LOG,
  ROUTE_AI_DEMO_PLAN
} from '@/router/router-constants'

const menu = [
  {
    id: 'customer',
    title: '业务',
    menus: [
      { id: ROUTE_CUSTOMER, title: '客户' },
      { id: ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE, title: '客户AI图' },
      { id: ROUTE_PROMPT_TEMPLATE, title: 'Prompt模版' },
      { id: ROUTE_AI_DEMO_PLAN, title: '测图计划' }
    ]
  },
  {
    id: 'manage',
    title: '维护',
    menus: [
      { id: ROUTE_USER, title: '用户管理' },
      { id: ROUTE_COMFY_UI, title: 'ComfyUI API' },
      { id: ROUTE_CONFIG, title: '系统配置' },
      { id: ROUTE_POINT_HISTORY, title: '客户点数变化记录' },
      { id: ROUTE_AI_LOG, title: 'AI日志' }
    ]
  }
]

export default menu
