import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

export const list = defineApi((config, params) => {
  config.params = params
  http.get(path('/customer/list'), config)
})

export const pointCharge = defineApi((config, id, point) => {
  config.params = {
    id: id,
    addPoint: point
  }
  http.post(path('/customer/point_charge'), config)
})
