<template>
  <app-page :route-name="routeName" :use-tcb-layout="true">
    <ul slot="top" class="tab-head">
      <li v-for="item in tabs"
          :key="item.id"
          class="tab-item"
          :class="{'selected': selectedTab === item.id}"
          @click="handleTabItemClick(item)">{{item.name}}</li>
    </ul>

    <div slot="center" class="tab-body">
      <business-config ref="business" v-show="selectedTab === 'business'"></business-config>
      <point-product-config ref="pointProduct" v-show="selectedTab === 'pointProduct'"></point-product-config>
      <vip-service-config ref="vipService" v-show="selectedTab === 'vipService'"></vip-service-config>
      <customer-login ref="customerLogin" v-show="selectedTab === 'customerLogin'"></customer-login>
      <stable-diffusion-prompt-text ref="sdPromptText" v-show="selectedTab === 'sdPromptText'"></stable-diffusion-prompt-text>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_CONFIG } from '@/router/router-constants'
import BusinessConfig from './business'
import PointProductConfig from './point-product'
import VipServiceConfig from './vip-service'
import CustomerLogin from './customer-login'
import StableDiffusionPromptText from './stable-diffusion-prompt-text'

export default {
  components: { BusinessConfig, PointProductConfig, VipServiceConfig, CustomerLogin, StableDiffusionPromptText },
  data () {
    const tabs = [
      { id: 'business', name: '业务' },
      { id: 'vipService', name: 'VIP套餐' },
      { id: 'pointProduct', name: '加油包' },
      { id: 'customerLogin', name: '客户登录服务' },
      { id: 'sdPromptText', name: 'StableDiffusion提示词' }
    ]
    tabs.forEach(item => (item.firstShow = true))

    return {
      routeName: ROUTE_CONFIG,
      tabs: tabs,
      selectedTab: null
    }
  },
  methods: {
    handleTabItemClick (item) {
      const prevTab = this.tabs.find(t => t.id === this.selectedTab)
      this.selectedTab = item.id
      const $comp = this.$refs[item.id]
      $comp.show(item.firstShow)
      item.firstShow = false

      if (prevTab) {
        this.$refs[prevTab.id].hide()
      }
    }
  },
  mounted () {
    this.handleTabItemClick(this.tabs[0])
  }
}
</script>

<style lang="less" scoped>
.tab-head {
  display: flex;
  padding: 8px 0 0 0;
  list-style-type: none;
  background-color: #fff;
  border-bottom: solid 1px #ddd;
  .tab-item {
    padding: 6px 22px;
    font-size: 13px;
    cursor: pointer;
    &.selected {
      color: #a94442;
      border-bottom: solid 2px #a94442;
    }
  }
}

.tab-body {
}
</style>
