import {
  ROUTE_ROLE,
  ROUTE_USER,
  ROUTE_AI_DEMO_PLAN,
  ROUTE_AI_DEMO_IMAGE
} from '@/router/router-constants'
import User from '@/pages/base/user'
import Role from '@/pages/base/user/role'
import AiDemoPlan from '@/pages/main/demo-plan'
import AiDemoImage from '@/pages/main/demo-plan/plan-detail'


export default [
  {
    name: ROUTE_USER,
    path: 'user',
    component: User,
    meta: { title: '用户管理' },
    children: [
      {
        name: ROUTE_ROLE,
        path: 'role',
        component: Role,
        meta: { title: '用户角色' }
      }
    ]
  },
  {
    name: ROUTE_AI_DEMO_PLAN,
    path: 'ai-demo-plan',
    component: AiDemoPlan,
    meta: { title: '测图计划' },
    children: [
      {
        name: ROUTE_AI_DEMO_IMAGE,
        path: ':id/image',
        component: AiDemoImage,
        meta: { title: '测图计划详情' }
      }
    ]
  }
]
