<template>
  <modal ref="modal" title="创建测图计划" :width="1200" @ok="handleConfirm" @closed="onClosed" :loading="loading">

    <div style="text-align: center;">
      <a-form layout="inline">
        <a-form-item label="标题">
          <a-input v-model="title" style="width: 300px;"></a-input>
        </a-form-item>
        <a-form-item label="ComfyUI版本">
          <a-select style="width: 300px; margin-bottom: 20px;" v-model="comfyUiId">
            <a-select-option v-for="opt in comfyOptions" :key="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>

    <div class="image-container">
      <light-image-selector ref="mainImage" width="250px" title="主图" :max-size="1024 * 1024 * 20"></light-image-selector>
      <light-image-selector ref="maskImage" width="250px" title="蒙版图" :max-size="1024 * 1024 * 20" style="margin-left: 20px;">
        <a-button slot="toolbar" size="small" shape="circle" @click="handleOpenCutout">扣</a-button>
      </light-image-selector>
    </div>

    <a-divider orientation="left">正向提示词</a-divider>
    <a-button @click="handleNewWordGroup">添加词组（支持中文）</a-button>
    <div class="word-group">
      <draggable
          tag="div"
          v-model="words"
          v-bind="{ animation: 200, ghostClass: 'ghost', handle: '.list-group-item.head' }"
          @start="isDrag = true"
          @end="isDrag = false"
      >
        <transition-group tag="div" type="transition" class="list-group-container">
          <ul class="list-group" v-for="(g, index) in words" :key="g.id">
            <li class="list-group-item head">
              <div class="left-right-content ver-center">
                <span class="full">词组-{{index+1}}</span>
                <a-button type="link" @click="handleAddPromptText(g)">添加提示语</a-button>
                <a-button type="link" @click="handleRemoveGroup(g)">删除</a-button>
              </div>
            </li>
            <li class="list-group-item" v-for="s in g.prompts" :key="s.id">
              <div class="left-right-content ver-center">
                <div class="full">
                  <a-input v-model="s.text"></a-input>
                </div>
                <a-button type="link" @click="handleRemovePromptText(g, s)">删除</a-button>
              </div>
            </li>
          </ul>
        </transition-group>
      </draggable>
    </div>

    <a-divider orientation="left">负向提示词</a-divider>
    <a-textarea v-model="negPrompt" :auto-size="{ minRows: 3, maxRows: 5 }"/>

    <image-cutout ref="cutout" @confirm="onCutoutConfirm"></image-cutout>

  </modal>
</template>

<script>
import Draggable from 'vuedraggable'
import kit from '@/utils/kit'
import { createPlan, listComfyOptions } from '@/http/api/ai-demo-plan'
import LightImageSelector from '@/components/light-image-selector'
import ImageCutout from '@/components/image-cutout'

export default {
  components: { Draggable, LightImageSelector, ImageCutout },
  data () {
    return {
      words: [],
      negPrompt: null,
      isDrag: false,
      mainImageFile: null,
      maskImageFile: null,
      comfyOptions: [],
      comfyUiId: null,
      title: null,
      loading: false
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
      if (this.comfyOptions.length === 0) {
        listComfyOptions().success(resp => {
          this.comfyOptions = resp.data
          const item = this.comfyOptions.find(item => item.name.includes('-Live'))
          if (item) {
            this.comfyUiId = item.id
          }
        }).send()
      }
    },
    onClosed () {
      this.title = null
    },
    handleNewWordGroup () {
      this.words.push({
        id: kit.str.id(),
        prompts: [
          {
            id: kit.str.id(),
            text: 'prompt text'
          }
        ]
      })
    },
    handleRemoveGroup (g) {
      kit.arr.removeItem(this.words, g)
    },
    handleAddPromptText (group) {
      group.prompts.push({
        id: kit.str.id(),
        text: 'prompt text'
      })
    },
    handleRemovePromptText (group, p) {
      kit.arr.removeItem(group.prompts, p)
      if (group.prompts.length === 0) {
        this.handleRemoveGroup(group)
      }
    },
    handleConfirm () {
      const prompts = []
      for (const g of this.words) {
        const tmp = []
        for (const t of g.prompts) {
          tmp.push(t.text)
        }
        prompts.push(tmp)
      }
      this.loading = true
      createPlan()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('创建成功。')
          this.$emit('saved')
          this.$nextTick(() => (this.$refs.modal.close()))
        })
        .send({
          title: this.title,
          comfyUiId: this.comfyUiId,
          mainImage: this.$refs.mainImage.getImageDataUrl(),
          maskImage: this.$refs.maskImage.getImageDataUrl(),
          prompt: prompts,
          negPrompt: this.negPrompt
        })
    },
    onCutoutConfirm (obj) {
      this.$refs.maskImage.setImageUrl(obj.maskImage)
    },
    handleOpenCutout () {
      if (this.$refs.mainImage.getFile()) {
        this.$refs.cutout.open(this.$refs.mainImage.getFile())
      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-container {
  display: flex;
  justify-content: center;
}
.word-group {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #eee;
}
.list-group-container {
  //display: flex;
  //flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  .list-group {
    background-color: #fff;
    .list-group-item.head {
      cursor: pointer;
    }
  }
}
</style>
