<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="电话">
            <a-input v-model="searchForm.phone" :allowClear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="类型">
            <a-select style="width: 120px;" :allow-clear="true" v-model="searchForm.type">
              <a-select-option v-for="opt in $const.pointHistoryType.options" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-range-picker v-model="searchForm.dates" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_POINT_HISTORY } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { list } from '@/http/api/point-history'

export default {
  data () {
    return {
      routeName: ROUTE_POINT_HISTORY,
      dataColumns: [
        { title: '手机号', dataIndex: 'phone' },
        { title: '剩余点数', dataIndex: 'point' },
        { title: '变动点数', dataIndex: 'pointChanged' },
        { title: '时间', dataIndex: 'createTime' },
        { title: '类型', dataIndex: 'type', customRender: (type) => this.$const.pointHistoryType.text[type] },
        { title: '消息', dataIndex: 'message' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        phone: null,
        type: null,
        dates: []
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      if (p.dates.length === 2) {
        p.startDate = p.dates[0]
        p.endDate = p.dates[1]
      }
      delete p.dates
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
