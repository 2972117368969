import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

export const list = defineApi((config, params) => {
  config.params = params
  http.get(path('/ai_task/exec/image/list'), config)
})

export const execAITaskImageSegment = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post(path('/sam/run'), config)
})

export const execAITaskImageSegmentResult = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/sam/result', config)
})
