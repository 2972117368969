<template>
  <modal
      ref="mod"
      title="UI资源编辑器"
      :width="400"
      :loading="loading"
      @ok="onSave"
      @closed="onClosed"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="标题" class="app_required-input">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="编号" class="app_required-input">
        <a-input v-model="form.code"/>
      </a-form-model-item>
      <a-form-model-item label="描述">
        <a-textarea v-model="form.description" :auto-size="{ minRows: 2, maxRows: 2 }" />
      </a-form-model-item>
    </a-form-model>
  </modal>
</template>

<script>
import ProtectResourceUi from '@/data/model/protect-resource-ui'
import { addUi, updateUi } from '@/http/api/protect-resource'

export default {
  data () {
    return {
      form: new ProtectResourceUi(),
      loading: false
    }
  },
  methods: {
    open (model, defaultType) {
      this.$refs.mod.open()
      this.form = new ProtectResourceUi(model)
      this.form.type = defaultType
    },
    onSave () {
      this.loading = true
      const api = this.form.id ? updateUi : addUi
      api()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$nextTick(() => (this.$refs.mod.close()))
        })
        .send(this.form)
    },
    onClosed () {
      this.form = new ProtectResourceUi()
    }
  }
}
</script>

<style scoped>

</style>
