<template>
  <div>
    <div v-if="!role" class="font-mini text-muted content-center">要编辑角色的权限，请先选择一个角色。</div>
    <div v-else class="main-content">
      <div class="role-info left-right-content">
        <div class="full">
          <span class="font-mini text-muted">正在编辑</span>
          <span class="role-name">{{role.name}}</span>
          <span class="font-mini text-muted">的权限</span>
          <span class="font-mini text-muted" v-show="appliedProtectResourceTotal > 0">，已分配 {{appliedProtectResourceTotal}} 个保护资源组</span>
        </div>
        <div>
          <a-button @click="handleProtectResourceEditorOpen(null)">新增保护资源组</a-button>
        </div>
      </div>
      <div style="margin-top: 15px;">
        <a-input v-model="searchKeyword" @keyup.enter="handleSearch" :allow-clear="true" placeholder="输入关键字回车查询" style="width: 100%;"></a-input>
      </div>
      <a-empty v-show="isEmptyResult" style="margin-top: 20px;"></a-empty>
      <div class="protect-resource-list">
        <div class="pr-group" v-for="(g, index) in protectResourceGroups" :key="index" v-show="g.hasMatchedItem">
          <div class="title">
            <a-icon type="container" />
            {{g.title}}
          </div>
          <div class="pr-grid">
            <div class="pr-grid-item" :class="{'disabled': !r.enabled}" v-for="r in g.resources" :key="r.id" v-show="r._searchMatched">
              <div class="checkbox"><a-checkbox v-model="r.applied" @change="handleRoleProtectResourceApplyChanged($event, r)"></a-checkbox></div>

              <div class="main-content">
                <div class="name">{{r.name}}</div>
                <div class="desc text-muted font-mini" v-if="r.description">{{r.description}}</div>
              </div>
              <div class="buttons">
                <a-button size="small" @click="handleProtectResourceEditorOpen(r)">编辑</a-button>
                <a-button size="small" type="danger" @click="handleDeleteProtectResource(r)">删除</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading :loading="dataLoading"></loading>
    </div>

    <protect-resource-editor ref="protectResourceEditor" @saved="handleProtectResourceEditorSaved"></protect-resource-editor>
  </div>
</template>

<script>
import RoleModel from '@/data/model/role'
import { getRoleProtectResources, applyProtectResource } from '@/http/api/role'
import { deleteProtectResourceById } from '@/http/api/protect-resource'
import CurrentRoleProtectResource from '@/data/dto/current-role-protect-resource'
import kit from '@/utils/kit'
import ProtectResourceEditor from './protect-resource-editor'

export default {
  components: { ProtectResourceEditor },
  props: {
    role: RoleModel
  },
  data () {
    return {
      /**
       * [{title: String, resources: CurrentRoleProtectResource}]
       */
      protectResourceGroups: [],
      dataLoading: false,
      searchKeyword: null
    }
  },
  watch: {
    role () {
      if (this.role) {
        this.loadRoleProtectResources()
      } else {
        this.protectResourceGroups = []
      }
    }
  },
  computed: {
    appliedProtectResourceTotal () {
      let t = 0
      for (const g of this.protectResourceGroups) {
        for (const r of g.resources) {
          if (r.applied) {
            t++
          }
        }
      }
      return t
    },
    isEmptyResult () {
      for (const a of this.protectResourceGroups) {
        if (a.hasMatchedItem) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    loadRoleProtectResources () {
      this.dataLoading = true
      getRoleProtectResources()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          for (const groupName of Object.keys(resp.data)) {
            arr.push({
              title: groupName,
              hasMatchedItem: true,
              resources: kit.arr.newList(resp.data[groupName], CurrentRoleProtectResource, item => (item._searchMatched = true))
            })
          }
          this.protectResourceGroups = arr
        })
        .send(this.role.id)
    },
    handleProtectResourceEditorOpen (protectResourceModel) {
      this.$refs.protectResourceEditor.open(protectResourceModel)
    },
    /**
     * 权限分配更新
     * @param e
     * @param protectResource
     */
    handleRoleProtectResourceApplyChanged (e, protectResource) {
      applyProtectResource()
        .success(() => {
          this.$message.success(`【${protectResource.name}】操作成功。`)
        })
        .send(this.role.id, protectResource.id, e.target.checked ? 'apply' : 'remove')
    },
    handleProtectResourceEditorSaved () {
      this.loadRoleProtectResources()
    },
    handleDeleteProtectResource (r) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${r.name}】吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteProtectResourceById()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadRoleProtectResources()
            })
            .send(r.id)
        }
      })
    },
    handleSearch () {
      const k = this.searchKeyword
      for (const group of this.protectResourceGroups) {
        group.hasMatchedItem = false
        for (const res of group.resources) {
          if (kit.str.isBlank(k) ||
              res.name.includes(k) ||
              (res.description && res.description.includes(k)) ||
              this.arrayStringMatch(res.protectResourceItemValues, k)
          ) {
            group.hasMatchedItem = true
            res._searchMatched = true
          } else {
            res._searchMatched = false
          }
        }
      }
    },
    arrayStringMatch (arr, search) {
      for (const s of arr) {
        if (s.includes(search)) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.main-content {
  position: relative;
  margin-left: 20px;
}
.role-info {
  padding: 4px 0;
  border-bottom: solid 1px #eee;
  .role-name {
    padding: 0 10px;
    font-size: 18px;
  }
}

// 角色的权限
.protect-resource-list {
  margin-top: 20px;
  .pr-group + .pr-group {
    margin-top: 20px;
  }
  .pr-group {
    border: solid 1px #eee;
    &>.title {
      padding: 8px;
      font-size: 16px;
      border-bottom: solid 1px #eee;
      background-color: #f9f9f9;
    }
    .pr-grid {
      display: block;
      padding: 14px;
      .pr-grid-item {
        display: flex;
        padding: 8px;
        &:hover {
          background-color: #f9f9f9;
        }
        .main-content {
          flex: 1;
          margin: 0 10px;
          .name {
            font-size: 14px;
            color: #2a2a2a;
          }
        }
        &.disabled .name {
          text-decoration: line-through;
          color: #80181b;
        }
        .buttons {
          visibility: hidden;
        }
        &:hover .buttons {
          visibility: visible;
        }
      }
    }
  }
}
</style>
