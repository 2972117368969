import {
  ROUTE_CUSTOMER,
  ROUTE_POINT_HISTORY,
  ROUTE_PROMPT_TEMPLATE,
  ROUTE_CONFIG,
  ROUTE_COMFY_UI,
  ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE,
  ROUTE_AI_LOG
} from '@/router/router-constants'
import Customer from '@/pages/main/customer'
import PointHistory from '@/pages/main/manage/point-history'
import PromptTemplate from '@/pages/main/prompt-template'
import Config from '@/pages/main/manage/config'
import ComfyUi from '@/pages/main/manage/comfy-ui'
import CustomerAiTaskExecImage from '@/pages/main/customer-ai-image'
import AiLog from '@/pages/main/manage/ai-log'

export default [
  {
    name: ROUTE_CONFIG,
    path: 'config',
    component: Config,
    meta: { title: '系统配置' }
  },
  {
    name: ROUTE_CUSTOMER,
    path: 'customer',
    component: Customer,
    meta: { title: '客户' }
  },
  {
    name: ROUTE_POINT_HISTORY,
    path: 'point-history',
    component: PointHistory,
    meta: { title: '客户点数变化记录' }
  },
  {
    name: ROUTE_PROMPT_TEMPLATE,
    path: 'prompt-template',
    component: PromptTemplate,
    meta: { title: 'Prompt模版' }
  },
  {
    name: ROUTE_COMFY_UI,
    path: 'comfy-ui',
    component: ComfyUi,
    meta: { title: 'ComfyUI API' }
  },
  {
    name: ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE,
    path: 'customer-ai-task-exec-image',
    component: CustomerAiTaskExecImage,
    meta: { title: '客户AI图' }
  },
  {
    name: ROUTE_AI_LOG,
    path: 'ai-log',
    component: AiLog,
    meta: { title: 'AI日志' }
  }
]
