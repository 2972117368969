/**
 * @author jiangxingshang
 * @date 2023/11/30
 */
import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const listAll = defineApi((config) => {
  http.get(path('/comfy_ui/list'), config)
})

export const add = defineApi((config, data) => {
  config.data = data
  http.post(path('/comfy_ui/add'), config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post(path('/comfy_ui/update'), config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/comfy_ui/delete'), config)
})

export const getWebSocketStatus = defineApi((config) => {
  http.get(path('/comfy_ui/websocket/status'), config)
})

