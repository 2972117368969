<template>
  <div class="image-cutout" v-show="showImageCutout">
    <div class="model-mask"></div>
    <div class="model-header">
      <a-icon type="close" class="model-head-btn" @click="close"/>
    </div>
    <div class="model-content">
      <div class="describe-label">点击图片中所有保持不变的区域</div>
      <div ref="regionContent" class="region-content">
        <div ref="regionWrap" class="region-wrap" :style="regionWrapStyle" @mousedown="handleRegionWrapMousedown" @mousemove="handleRegionWrapMousemove" @mouseleave="handleRegionWrapMouseleave">
          <img ref="regionImage" :src="imageSrc" alt="region" class="region-image">
          <template v-for="(ml, index) in maskList">
            <img class="mask-image" :id="`temp-${index}`" :src="ml.src" :style="maskStyle(ml)" :key="`temp-${index}`" alt="mask">
          </template>
          <template v-for="(ml, index) in maskHoverList">
            <img class="mask-image" :id="`hover-${index}`" :src="ml.src" :style="maskStyle(ml)" :key="`hover-${index}`" alt="mask">
          </template>
        </div>
      </div>
      <div class="bottom-opt-bar">
        <div class="opt-btn-left btn-text" @click="handleResetMaskList"><a-icon type="reload" class="icon"/>重选</div>
        <div class="btn-text" @click="handleUndoMaskList"><a-icon type="undo" class="icon"/>撤销</div>
        <div class="opt-btn-right btn-text" @click="handleRegionOk"><a-icon type="check-square" class="icon"/>确定</div>
      </div>
      <div class="segment-loading" v-if="loadingSegment">
        <a-spin :spinning="loadingSegment" size="large"></a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { execAITaskImageSegment, execAITaskImageSegmentResult } from '@/http/api/ai-task-exec-image'
export default {
  data () {
    return {
      showImageCutout: false,
      loadingSegment: true,
      file: null,
      imageSrc: null,
      maskList: [],
      masksData: null,
      imgWidth: 0,
      imgHeight: 0,
      imgScale: 1,
      isMaskInterval: false,
      maskInterval: null,
      maskTempList: [],
      maskHoverList: [],
      mask: '',
      isRegionOkLoading: false,
      imageSegmentResultIntervals: {}
    }
  },
  computed: {
    maskStyle () {
      return function (mask) {
        const width = mask.scaleW ? mask.scaleW : mask.width * this.imgScale
        const height = mask.scaleW ? mask.scaleH : mask.height * this.imgScale
        return {
          width: `${width}px`,
          height: `${height}px`,
          top: `${mask.top * this.imgScale}px`,
          left: `${mask.left * this.imgScale}px`
        }
      }
    },
    regionWrapStyle () {
      return {
        width: `${this.imgWidth}px`,
        height: `${this.imgHeight}px`
      }
    }
  },
  methods: {
    open (file) {
      this.file = file
      this.loadingSegment = true
      this.showImageCutout = true
      this.getBase64(file, imageUrl => {
        this.imageSrc = imageUrl
      })

      this.maskList = []
      this.imgScale = 1

      this.execAITaskImageSegment()
    },
    close () {
      this.handleModelClose()
    },
    handleModelClose () {
      this.showImageCutout = false
      this.imageSrc = null
      this.masksData = null
      this.imgWidth = 0
      this.imgHeight = 0
      this.maskList = []
      this.isMaskInterval = false
      this.imageSegmentResultIntervals = {}
      if (this.maskInterval) {
        clearInterval(this.maskInterval)
        this.maskInterval = null
      }
    },
    execAITaskImageSegment () {
      execAITaskImageSegment()
        .complete(() => {})
        .success(res => {
          if (res.data) {
            this.handleExecAITaskImageSegmentResult(res.data)
          } else {
            this.handleLoadingSegmentFail()
          }
        })
        .error(() => {
          this.handleLoadingSegmentFail()
        })
        .send(this.file)
    },
    handleLoadingSegmentFail () {
      const that = this
      this.$error({
        title: '失败提示',
        content: '图片蒙版切块生成失败',
        okText: '关闭',
        onOk () {
          that.handleModelClose()
        }
      })
    },
    handleExecAITaskImageSegmentResult (id) {
      if (!this.imageSegmentResultIntervals[id]) {
        this.imageSegmentResultIntervals[id] = setInterval(() => {
          execAITaskImageSegmentResult()
            .complete(() => {})
            .success(res => {
              const data = res.data
              if (data) {
                if (data === 'error') {
                  this.handleLoadingSegmentFail()
                } else if (data !== 'progress') {
                  clearInterval(this.imageSegmentResultIntervals[id])
                  delete this.imageSegmentResultIntervals[id]

                  this.masksData = data.masks.reverse().map((obj, index) => {
                    const mask = obj.mask.map(item => {
                      const newItem = new Array(obj.w)
                      const itemLength = item.length / 2
                      let start = 0
                      for (let i = 0; i < itemLength; i++) {
                        const end = start + item[i * 2 + 1] - 1
                        newItem.fill(item[i * 2], start, end)
                        start = end + 1
                      }
                      return newItem
                    })

                    return Object.assign({}, obj, {
                      mask: mask,
                      id: index
                    })
                  })

                  this.imgWidth = data.width
                  this.imgHeight = data.height
                  this.loadingSegment = false

                  this.maskInterval = setInterval(() => {
                    this.isMaskInterval = false
                  }, 260)
                }
              } else {
                this.handleLoadingSegmentFail()
              }
            })
            .error(() => {
              this.handleLoadingSegmentFail()
            })
            .send(id)
        }, 3000)
      }
    },
    getBase64 (img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    handlePaintingBrushClick () {
      this.$emit('showImagePaintingBrush', this.maskList)
    },
    handleResetMaskList () {
      this.maskList = []
    },
    handleUndoMaskList () {
      this.maskList.pop()
    },
    handleRegionOk () {
      if (this.maskList.length === 0) {
        this.$message.warning('请选择图片中保持不变的区域')
      } else {
        if (!this.isRegionOkLoading) {
          this.isRegionOkLoading = true
          this.masksToRegionResult()
        }
      }
    },
    handleRegionWrapMousedown (ev) {
      ev = ev || window.event
      const x = Math.floor(ev.offsetX / this.imgScale)
      const y = Math.floor(ev.offsetY / this.imgScale)
      this.pointToMask(x, y)
    },
    handleRegionWrapMousemove (ev) {
      ev = ev || window.event
      const x = Math.floor(ev.offsetX / this.imgScale)
      const y = Math.floor(ev.offsetY / this.imgScale)
      if (!this.isMaskInterval) {
        this.isMaskInterval = true
        this.pointToMask(x, y, true)
      }
    },
    handleRegionWrapMouseleave () {
      this.maskHoverList = []
    },
    pointToMask (x, y, isHover = false) {
      this.maskTempList = []
      for (let i = 0; i < this.masksData.length; i++) {
        const mask = this.masksData[i]
        if (!(x < mask.x || x > (mask.x + mask.w) || y < mask.y || y > (mask.y + mask.h))) {
          const indexX = Math.max(0, x - mask.x - 1)
          const indexY = Math.max(0, y - mask.y - 1)
          if (mask.mask[indexY][indexX] === 1) {
            if (isHover) {
              this.maskToImage(mask, isHover)
            } else {
              if (this.maskList.findIndex(item => item.id === mask.id) === -1) {
                this.maskToImage(mask, isHover)
              }
            }
            return
          } else {
            this.maskTempList.push(mask)
          }
        }
      }
    },
    maskToImage (maskData, isHover = false) {
      const canvas = document.createElement('canvas')
      canvas.width = maskData.w
      canvas.height = maskData.h
      const ctx = canvas.getContext('2d')
      const arr = maskData.mask
      const imgData = ctx.getImageData(0, 0, maskData.w, maskData.h)
      for (let i = 0; i < arr.length; i++) {
        const itemW = arr[i]
        for (let j = 0; j < itemW.length; j++) {
          if (itemW[j] === 1) {
            const index = i * itemW.length + j
            imgData.data[index * 4] = 118
            imgData.data[index * 4 + 1] = 48
            imgData.data[index * 4 + 2] = 253
            imgData.data[index * 4 + 3] = 120
          }
        }
      }
      ctx.putImageData(imgData, 0, 0)
      const result = canvas.toDataURL('image/png')
      const item = {
        id: maskData.id,
        src: result,
        width: maskData.w,
        height: maskData.h,
        top: maskData.y,
        left: maskData.x
      }
      if (isHover) {
        this.maskHoverList = [item]
      } else {
        this.maskList.push(item)
      }
    },
    masksToRegionResult () {
      this.loadingSegment = true
      const canvas = document.createElement('canvas')
      canvas.width = this.imgWidth
      canvas.height = this.imgHeight
      const ctx = canvas.getContext('2d')
      for (let i = 0; i < this.maskList.length; i++) {
        const mask = this.maskList[i]
        const pic = document.getElementById(`temp-${i}`)
        ctx.drawImage(pic, mask.left, mask.top, mask.width, mask.height)
      }
      const maskImageEdit = canvas.toDataURL('image/png')

      this.maskToWhiteBg(maskImageEdit).then(res => {
        this.isMaskInterval = false
        this.isRegionOkLoading = false
        this.loadingSegment = false
        this.$emit('confirm', {
          maskImage: res,
          maskImageEdit: maskImageEdit
        })
        this.handleModelClose()
      }, () => {
        this.$message.error('蒙版图转换失败')
      })
    },
    maskToWhiteBg (data) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0)
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const length = imageData.data.length
          for (let index = 0; index < length; index += 4) {
            const red = imageData.data[index]
            const green = imageData.data[index + 1]
            const blue = imageData.data[index + 2]
            const average = Math.floor((red + green + blue) / 3)
            imageData.data[index] = average
            imageData.data[index + 1] = average
            imageData.data[index + 2] = average

            if (imageData.data[index + 3] === 0 || (imageData.data[index] === 0 && imageData.data[index + 1] === 0 && imageData.data[index + 2] === 0) ||
              (imageData.data[index] <= 40 && imageData.data[index + 1] <= 40 && imageData.data[index + 2] <= 40)) {
              imageData.data[index] = 0
              imageData.data[index + 1] = 0
              imageData.data[index + 2] = 0
              imageData.data[index + 3] = 255
            } else {
              imageData.data[index] = 255
              imageData.data[index + 1] = 255
              imageData.data[index + 2] = 255
              imageData.data[index + 3] = 255
            }
          }
          ctx.putImageData(imageData, 0, 0)

          const result = canvas.toDataURL('image/jpeg')
          resolve(result)
        }
        image.src = data
        image.onerror = () => {
          reject(new Error('maskToWhiteBg error'))
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.image-cutout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .model-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(20, 20, 20, 0.9);
  }

  .model-header {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    align-items: center;
    padding-right: 24px;
    .model-head-btn {
      font-size: 40px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .model-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 90px;
    .segment-loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      background: rgba(20, 20, 20, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .describe-label {
      color: #ffffff;
      text-align: center;
      margin: 20px auto;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    .region-content {
      width: 100vw;
      height: 100%;
      max-height: calc(100% - 132px);
      overflow: auto;
      position: relative;
      margin-bottom: 20px;
      .region-wrap {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        min-width: 570px;
        min-height: 640px;
        cursor: pointer;
        .region-image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .mask-image {
          position: absolute;
          pointer-events: none;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .bottom-opt-bar {
      width: 100%;
      height: 48px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-text {
        cursor: pointer;
        width: 100px;
        height: 48px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        .icon {
          margin-right: 6px;
        }
      }

      .opt-btn-left {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        width: 110px;
        padding-left: 10px;
      }

      .opt-btn-right {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        width: 110px;
        padding-right: 10px;
      }
    }
  }
}
</style>
