<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :use-tcb-layout="false">

    <div class="container">
      <div class="role-container section">
        <div class="head">
          <span class="title">角色列表</span>
          <span class="toolbar">
            <a-button size="small" type="link" @click="handleOpenRoleEditor(null)">添加</a-button>
          </span>
        </div>
        <div class="body">
          <div class="role-list">
            <div v-for="r in roleList"
                 :key="r.id"
                 class="role-item"
                 :class="{'selected': r.id === selectedRoleId, 'disabled': !r.enabled}"
                 @click="selectedRoleId = r.id"
            >
              <div class="name">
                <span class="inner-name">{{r.name}}</span>
                <span v-show="r.alias" class="font-mini text-muted">（{{r.alias}}）</span>
              </div>
              <div class="desc">{{r.description}}</div>
              <div class="buttons">
                <a-button size="small" icon="edit" @click="handleOpenRoleEditor(r)"></a-button>
                <a-button size="small" type="danger" icon="delete" @click="handleDeleteRole(r)"></a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="protect-resource-container">
        <role-protect-resource :role="selectedRole"></role-protect-resource>
      </div>
    </div>

    <modal
        ref="roleEditor"
        title="角色编辑"
        :loading="roleEditor.updating"
        @ok="handleRoleEditorSave"
    >
      <a-form-model ref="form" :model="roleEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="名称" class="app_required-input">
          <a-input v-model="roleEditor.form.name"/>
        </a-form-model-item>
        <a-form-model-item label="别名">
          <a-input v-model="roleEditor.form.alias"/>
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-textarea v-model="roleEditor.form.description" :auto-size="{ minRows: 2, maxRows: 2 }" />
        </a-form-model-item>
        <a-form-model-item label="是否可用">
          <a-switch v-model="roleEditor.form.enabled"></a-switch>
        </a-form-model-item>
      </a-form-model>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_ROLE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listAllRoles, addRole, updateRole, deleteRole } from '@/http/api/role'
import RoleModel from '@/data/model/role'
import kit from '@/utils/kit'
import RoleProtectResource from './comp/role-protect-resource'

export default {
  components: { RoleProtectResource },
  data () {
    return {
      routeName: ROUTE_ROLE,
      roleList: [],
      selectedRoleId: null,
      dataLoading: false,
      roleEditor: {
        updating: false,
        isUpdate: false,
        form: new RoleModel()
      }
    }
  },
  mixins: [AntdTableHeightMixin],
  computed: {
    selectedRole () {
      return this.roleList.find(item => item.id === this.selectedRoleId)
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listAllRoles()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.roleList = kit.arr.newList(resp.data, RoleModel)
        })
        .send()
    },
    handleOpenRoleEditor (model) {
      this.roleEditor.isUpdate = !!model
      this.roleEditor.form = new RoleModel(model)
      this.roleEditor.visible = true
      this.$refs.roleEditor.open()
    },
    handleRoleEditorSave () {
      this.roleEditor.updating = true
      const api = this.roleEditor.isUpdate ? updateRole : addRole
      api()
        .complete(() => (this.roleEditor.updating = false))
        .success(resp => {
          this.$message.success('保存成功。')
          kit.arr.pushOrReplace(this.roleList, new RoleModel(resp.data))
          this.$nextTick(() => {
            this.$refs.roleEditor.close()
          })
        })
        .send(this.roleEditor.form)
    },
    handleDeleteRole (role) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${role.name}】吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteRole()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              kit.arr.removeItem(this.roleList, role)
            })
            .send(role.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  @padding: 10px;
  left: @padding;
  top: @padding;
  right: @padding;
  bottom: @padding;
  display: flex;
  padding: 10px;
  background-color: #fff;
}
.section {
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-bottom: solid 1px #eee;
    .title {
      flex: 1;
      font-weight: bold;
    }
    .toolbar {

    }
  }
  .body {
    flex: 1;
    overflow-y: auto;
  }
}
.role-container {
  width: 450px;
  min-width: 340px;
  border: solid 1px #eee;
  .role-list {

  }
  .role-item + .role-item {
    border-top: solid 1px #eee;
  }
  .role-item {
    padding: 8px 10px;
    cursor: pointer;
    .name {

    }
    .desc {
      font-size: 12px;
      color: #9a9999;
    }
    .buttons {
      display: none;
      margin-top: 8px;
      text-align: right;
    }
    &:hover {
      background-color: #f9f9f9;
      .buttons {
        display: block;
      }
    }
    &.selected {
      background-color: #f9f9f9;
      .buttons {
        display: block;
      }
    }
    &.disabled {
      .inner-name {
        text-decoration: line-through;
        color: #80181b;
      }
    }
  }
}
.protect-resource-container {
  flex: 1;
}
</style>
