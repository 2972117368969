<template>
  <app-page :route-name="routeName" :use-tcb-layout="false">
  </app-page>
</template>

<script>
import { ROUTE_DASHBOARD } from '@/router/router-constants'

export default {
  data () {
    return {
      routeName: ROUTE_DASHBOARD
    }
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
</style>
