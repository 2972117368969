/**
 * @author zf
 * @date 2024/1/23
 */
import kit from '@/utils/kit'

export const AI_TASK_EXEC_IMAGE_STATUS_QUEUE = 'queue'
export const AI_TASK_EXEC_IMAGE_STATUS_PROGRESS = 'progress'
export const AI_TASK_EXEC_IMAGE_STATUS_COMPLETED = 'completed'
export const AI_TASK_EXEC_IMAGE_STATUS_FAILED = 'failed'

const mapping = {
  queue: AI_TASK_EXEC_IMAGE_STATUS_QUEUE,
  progress: AI_TASK_EXEC_IMAGE_STATUS_PROGRESS,
  completed: AI_TASK_EXEC_IMAGE_STATUS_COMPLETED,
  failed: AI_TASK_EXEC_IMAGE_STATUS_FAILED
}

const text = {}
text[mapping.queue] = '在排队'
text[mapping.progress] = '正在执行AI运算'
text[mapping.completed] = '完成'
text[mapping.failed] = '出错'


export default {
  mapping: mapping,
  text: text,
  options: kit.obj.toArray(text)
}
