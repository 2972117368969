<template>
  <div>
    <div>
      <a-button type="primary" @click="onEditorOpen()" style="margin-right: 20px;">新增套餐</a-button>
      <a-tooltip placement="right">
        <template slot="title">
          <span v-if="config.enabled">套餐服务已开启，客户可看到套餐列表。</span>
          <span v-else>套餐服务已关闭，客户看不到套餐列表。</span>
        </template>
        开启/关闭套餐服务
        <a-switch v-model="config.enabled" @click="onConfigEnabledClick"></a-switch>
      </a-tooltip>
    </div>

    <a-table
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="vipServices"
        :pagination="false"
        :loading="dataLoading"
    >

      <div slot="buttons" slot-scope="row">
        <a-button size="small" @click="onEditorOpen(row)" icon="edit"></a-button>
        <a-button size="small" @click="handleRemoveVipService(row)" icon="delete" type="danger"></a-button>
      </div>


      <div slot="discountOnPointProduct" slot-scope="row">
        <span v-if="row.discountOnPointProduct === 1">不打折</span>
        <span v-else>{{row.discountOnPointProduct * 10}}折</span>
        /
        <span v-if="row.discountOnPointProductVisible" class="text-success font-mini">显示</span>
        <span v-else class="text-muted font-mini">隐藏</span>
        /
        <span v-if="row.discountOnPointProductEnabled" class="text-success font-mini">可用</span>
        <span v-else class="text-danger font-mini">禁用</span>
      </div>

      <div slot="runningTaskTotal" slot-scope="row">
        {{row.runningTaskTotal}}
        /
        <span v-if="row.runningTaskTotalVisible" class="text-success font-mini">显示</span>
        <span v-else class="text-muted font-mini">隐藏</span>
        /
        <span v-if="row.runningTaskTotalEnabled" class="text-success font-mini">可用</span>
        <span v-else class="text-danger font-mini">禁用</span>
      </div>

      <div slot="price" slot-scope="row">
        <div v-for="(p, index) in row.prices" :key="index" class="price-row">
          价格：{{p.price}} | 点数：{{p.point}} | 有效天数：{{p.days}}
        </div>
      </div>
    </a-table>

    <modal
        ref="editor"
        title="套餐编辑器"
        :loading="editor.loading"
        @ok="onEditorConfirm"
        @closed="onEditorClosed"
    >
      <a-form-model class="editor-form" ref="form" :model="editor.form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="标题" class="app_required-input">
          <a-input v-model="editor.form.title"/>
        </a-form-model-item>
        <a-form-model-item label="类型" class="app_required-input">
          <a-select style="width: 100%;" v-model="editor.form.type">
            <a-select-option value="default">默认</a-select-option>
            <a-select-option value="once">一次性</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-divider>套餐特点</a-divider>

        <a-form-model-item label="加油包折扣" class="app_required-input">
          <div class="flex-wrapper">
            <div class="full-wrapper">
              <a-input-number v-model="editor.form.discountOnPointProduct" :min="0" :max="1" :precision="2" :step="0.01"/>
            </div>
            <a-switch v-model="editor.form.discountOnPointProductVisible" checked-children="显示" un-checked-children="隐藏" style="margin: 0 12px;"></a-switch>
            <a-switch v-model="editor.form.discountOnPointProductEnabled" checked-children="可用" un-checked-children="禁用"></a-switch>
          </div>
          <div class="prompt text-muted font-mini">此套餐的客户在购买加油包时可打的折扣，如8折，请填0.8，不打折请填1。</div>
        </a-form-model-item>

        <a-form-model-item label="同时运行任务数" class="app_required-input">
          <div class="flex-wrapper">
            <div class="full-wrapper">
              <a-input-number v-model="editor.form.runningTaskTotal" :min="1" :precision="0" :step="1"/>
            </div>
            <a-switch v-model="editor.form.runningTaskTotalVisible" checked-children="显示" un-checked-children="隐藏" style="margin: 0 12px;"></a-switch>
            <a-switch v-model="editor.form.runningTaskTotalEnabled" checked-children="可用" un-checked-children="禁用"></a-switch>
          </div>
        </a-form-model-item>

        <a-divider>阶梯价格</a-divider>

        <ul class="list-group">
          <li class="list-group-item">
            <div class="list-item-wrapper">
              <div class="price">价格</div>
              <div class="days">有效天数</div>
              <div class="point">点数</div>
              <div class="buttons">操作</div>
            </div>
          </li>
          <li class="list-group-item" v-for="item in editor.form.prices" :key="item.id">
            <div class="list-item-wrapper">
              <div class="price">
                <a-input-number v-model="item.price" :min="0" :precision="2"/>
              </div>
              <div class="days">
                <a-tooltip placement="right">
                  <template slot="title">一次性套餐的内容，从购买日起，在有效天数内有效，过了会自动变为默认套餐。</template>
                  <a-input-number v-model="item.days" :min="1" :max="2000" :precision="0" :step="10"/>
                </a-tooltip>
              </div>
              <div class="point">
                <a-input-number v-model="item.point" :min="0" :precision="0" :step="10"/>
              </div>
              <div class="buttons">
                <a-button type="danger" icon="delete" size="small" @click="handleRemovePrice(item)"></a-button>
              </div>
            </div>
          </li>
        </ul>
        <a-button @click="handleAddPrice" style="margin-top: 10px; width: 100%;">添加价格</a-button>

      </a-form-model>
    </modal>
  </div>
</template>

<script>
import { getVipServiceConfig, updateVipServiceConfig } from '@/http/api/config'
import kit from '@/utils/kit'

function buildEditorForm () {
  return {
    id: null,
    title: null,
    type: 'once',
    discountOnPointProduct: 1,
    discountOnPointProductVisible: true,
    discountOnPointProductEnabled: true,
    runningTaskTotal: 1,
    runningTaskTotalVisible: true,
    runningTaskTotalEnabled: true,
    prices: []
  }
}

export default {
  data () {
    return {
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', width: 80, scopedSlots: { customRender: 'buttons' } },
        { title: '套餐名称', width: 200, dataIndex: 'title' },
        { title: '类型', width: 80, dataIndex: 'type', customRender: type => type === 'once' ? '一次性' : '默认套餐' },
        { title: '加油包折扣', width: 130, scopedSlots: { customRender: 'discountOnPointProduct' } },
        { title: '任务运行数', width: 130, scopedSlots: { customRender: 'runningTaskTotal' } },
        { title: '价格', scopedSlots: { customRender: 'price' } }
      ],
      config: {
        enabled: false
      },
      vipServices: [],
      editor: {
        form: buildEditorForm(),
        loading: false,
        priceForm: {
          price: 0,
          point: 0,
          days: 1
        }
      }
    }
  },
  methods: {
    show (firstShow) {
      if (firstShow) {
        this.loadData()
      }
    },
    hide () {
    },
    loadData () {
      this.dataLoading = false
      getVipServiceConfig()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.config.enabled = resp.data.enabled
          this.vipServices = resp.data.services
        })
        .send()
    },
    updateConfig (content, callback) {
      this.editor.loading = true
      content.services.sort((a, b) => {
        let price1, price2
        if (a.prices && a.prices.length > 0) {
          price1 = a.prices[0].price
        }
        if (b.prices && b.prices.length > 0) {
          price2 = b.prices[0].price
        }
        return price1 - price2
      })
      updateVipServiceConfig()
        .complete(() => (this.editor.loading = false))
        .success(() => {
          this.loadData()
          this.$message.success('保存成功。')
          callback && callback()
        })
        .send(content)
    },
    onEditorOpen (model) {
      if (model) {
        this.editor.form = JSON.parse(JSON.stringify(model))
      } else {
        this.editor.form = buildEditorForm()
      }
      this.$refs.editor.open()
    },
    onEditorConfirm () {
      if (!this.editor.form.title) {
        return this.$message.error('标题不能为空。')
      }
      this.$confirm({
        title: '确认',
        content: '确认信息无误？',
        onOk: () => {
          const arr = [...this.vipServices]
          if (!this.editor.form.id) {
            this.editor.form.id = kit.str.id()
          }
          kit.arr.pushOrReplace(arr, this.editor.form)
          if (arr.filter(item => item.type === 'default').length > 1) {
            this.$message.error('只能添加一个默认套餐。')
          } else if (this.editor.form.prices.length === 0) {
            this.$message.error('阶梯价格至少添加一个。')
          } else {
            const data = {
              enabled: this.config.enabled,
              services: arr
            }
            this.updateConfig(data, () => {
              this.$nextTick(() => (this.$refs.editor.close()))
            })
          }
        }
      })
    },
    handleAddPrice () {
      this.editor.form.prices.push(Object.assign({
        id: kit.str.id()
      }, this.editor.priceForm))
      this.editor.form.prices.sort((a, b) => a.price - b.price)
      this.editor.priceForm.price = 0
      this.editor.priceForm.days = 1
      this.editor.priceForm.point = 0
    },
    handleRemovePrice (item) {
      kit.arr.removeItem(this.editor.form.prices, item)
    },
    onEditorClosed () {

    },
    onConfigEnabledClick () {
      const data = {
        enabled: this.config.enabled,
        services: this.vipServices
      }
      this.updateConfig(data)
    },
    handleRemoveVipService (row) {
      this.$confirm({
        title: '确认',
        content: `确定要移除套餐【${row.title}】吗？`,
        onOk: () => {
          kit.arr.remove(this.vipServices, row)
          this.updateConfig(Object.assign({
            services: this.vipServices
          }, this.config))
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.editor-form {
  .prompt {
    line-height: normal;
  }
}
.flex-wrapper {
  display: flex;
  align-items: center;
  .full-wrapper {
    flex: 1;
  }
}

.list-item-wrapper {
  display: flex;
  align-items: center;
  .price, .point, .days {
    flex: 1;
  }
}

.price-row + .price-row {
  margin-top: 4px;
}
</style>
