/**
 * @author jiangxingshang
 * @date 2023/2/21
 */

import UserModel from '../model/user'
import kit from '@/utils/kit'

export default class UserDto extends UserModel {
  constructor (initData) {
    super(initData)
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
