export const ROUTE_HOME = 'home'
export const ROUTE_DASHBOARD = 'dashboard'
export const ROUTE_LOGIN = 'login'
export const ROUTE_404 = 'not-found'

export const ROUTE_CUSTOMER = 'customer'

export const ROUTE_USER = 'user'
export const ROUTE_ROLE = 'role'

export const ROUTE_CONFIG = 'config'

export const ROUTE_POINT_HISTORY = 'point-history'

export const ROUTE_PROMPT_TEMPLATE = 'prompt-template'
export const ROUTE_COMFY_UI = 'comfy-ui'

export const ROUTE_CUSTOMER_AI_TASK_EXEC_IMAGE = 'ai-task-exec-image'
export const ROUTE_AI_LOG = 'ai-log'
export const ROUTE_AI_DEMO_PLAN = 'ai-demo-plan'
export const ROUTE_AI_DEMO_IMAGE = 'ai-demo-image'

