<template>
  <div>
    <a-form-model :model="config" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="通用正向提示词">
        <a-input v-model="config.promptText" type="textarea" :auto-size="{ minRows: 10, maxRows: 10 }" placeholder="英文逗号隔开每个词" />
      </a-form-model-item>
      <a-form-model-item label="通用负向提示词">
        <a-input v-model="config.negPromptText" type="textarea" :auto-size="{ minRows: 10, maxRows: 10 }" placeholder="英文逗号隔开每个词" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
        <a-button type="primary" @click="updateConfig()">保存</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { getStableDiffusionPromptText, updateStableDiffusionPromptText } from '@/http/api/config'

export default {
  data () {
    return {
      saving: false,
      config: {
        promptText: null,
        negPromptText: null
      }
    }
  },
  watch: {
  },
  methods: {
    show (firstShow) {
      if (firstShow) {
        this.loadData()
      }
    },
    hide () {
    },
    loadData () {
      this.dataLoading = false
      getStableDiffusionPromptText()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.config = resp.data
        })
        .send()
    },
    updateConfig () {
      this.saving = true
      updateStableDiffusionPromptText()
        .complete(() => (this.saving = false))
        .success(() => {
          this.loadData()
          this.$message.success('保存成功。')
        })
        .send(this.config)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
