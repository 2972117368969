import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

export const listPlan = defineApi((config, params) => {
  config.params = params
  http.get(path('/ai_demo_plan/list'), config)
})

export const getPlanById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get(path('/ai_demo_plan/get/by_id'), config)
})

export const listImageByPlanId = defineApi((config, planId) => {
  config.params = {
    planId: planId
  }
  http.get(path('/ai_demo_plan/list/image/by_plan'), config)
})

export const createPlan = defineApi((config, data) => {
  config.data = data
  http.post(path('/ai_demo_plan/add'), config)
})

export const listComfyOptions = defineApi((config) => {
  http.get(path('/ai_demo_plan/comfy_options'), config)
})

export const getPlanRunningStatus = defineApi((config, planId) => {
  config.params = { planId: planId }
  http.get(path('/ai_demo_plan/image/ai_running'), config)
})

export const startPlan = defineApi((config, planId) => {
  config.params = { planId: planId }
  http.post(path('/ai_demo_plan/image/ai_start'), config)
})

export const submitPlanImage = defineApi((config, planId, imageIdList) => {
  config.params = { planId: planId, imageIdList: imageIdList }
  http.post(path('/ai_demo_plan/image/ai_submit'), config)
})

export const shutdownPlan = defineApi((config, planId) => {
  config.params = { planId: planId }
  http.post(path('/ai_demo_plan/image/ai_shutdown'), config)
})

export const getImageStatusByPlan = defineApi((config, planId) => {
  config.params = { planId: planId }
  http.get(path('/ai_demo_plan/image/status'), config)
})
