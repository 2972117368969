<template>
  <transition name="fade" :duration="200">
    <div class="login-page fixed-full">

      <div id="login-bg-container" class="background-image absolute-full">
      </div>

      <div class="overlay absolute-full">

        <div class="panel-wrapper">
          <div class="logo"></div>
          <div class="app-info">
            <div class="app-name">{{name}}</div>
            <div class="app-version" v-show="version">{{version}}</div>
          </div>
          <div class="animate-wrapper">
            <login-panel ref="login"></login-panel>
          </div>
        </div>
        <div class="copyright"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import LoginPanel from './login'
import { appStore } from '@/store'

export default {
  components: { LoginPanel },
  data () {
    return {
    }
  },
  computed: {
    ...(appStore.mapState(['version', 'name', 'logoUrl']))
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="less">

.login-page {
  background-color: #fff;
  .background-image {
    z-index: 0;
    background-color: #fff;
  }

  .overlay {
    //z-index: 1;
    //background-color: rgba(0,0,0,.3);
  }

  @panelWrapperWidth: 400px;
  @panelWidth: @panelWrapperWidth - 2; // 2px边框，20左间距
  @panelBorderColor: #efefef;
  .panel-wrapper {
    position: absolute;
    top: 50%;
    right: 80px;
    margin-top: -237px;
    padding-top: 12px;
    width: @panelWrapperWidth;
    overflow: hidden;
    background-color: rgba(255,255,255,.8);
    box-shadow: 0 0 8px rgba(0,0,0,.4);
    border-radius: 3px;
    .app-info {
      margin-top: 5px;
      text-align: center;
      color: #155492;
    }
    .app-name {
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
    }
    .app-version {
      font-weight: normal;
      font-size: 12px;
      text-align: center;
    }
    //.logo {
    //  display: block;
    //  margin: 0 auto;
    //  background-size: contain;
    //  background-repeat: no-repeat;
    //  background-position: center;
    //  @size: 100px;
    //  width: @size;
    //  height: @size;
    //  line-height: @size;
    //  color: #fff;
    //  text-align: center;
    //  font-size: 62px;
    //  font-weight: bold;
    //  border-radius: 5px;
    //}
  }
  .animate-wrapper {
    width: @panelWrapperWidth * 2;
    white-space: nowrap;
    .panel {
      display: block;
      float: left;
      min-height: 100px;
      width: @panelWidth;
      padding: 10px 0;
      margin: 0 !important;
      vertical-align: top;
    }
  }

  .login-panel {
    .code-image {
      width: 100px;
      height: 29px !important;
      cursor: pointer;
      img {
        width: inherit;
        height: inherit;
      }
    }
    .clear-username {
      display: flex;
      flex-flow: column;
      justify-content: center;
      color: #949494;
      cursor: pointer;
    }
  }

  .panel {
    .form-item {
      display: flex;
      padding: 5px 15px;
      &:not(:first-of-type) {
        margin-top: 10px;
      }
      &.input-item {
        border-bottom: solid 1px @panelBorderColor;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
      }
      &.button-bar {
        display: block;
        text-align: right !important;
      }
      label {
        display: flex;
        width: 60px;
        flex-flow: column;
        justify-content: center;
        text-align: right;
        margin-right: 10px;
        color: #474747;
        font-size: 14px;
      }
      input {
        flex: 1;
        padding: 4px 0;
        background-color: transparent;
        border: none;
        color: #414141;
        font-weight: bold;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .copyright {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
</style>
