import kit from './kit'

/**
 * 弹出文件选择框。
 * 例：
 * import selectFile from 'select-file'
 * selectFile({
 *   select: (files, errors) => {
 *   }
 * })
 * @param options
 *   accept {Array | String} 限制文件类型。
 *   size {int} 限制文件大小，默认0，表示不限制。
 *   multi {boolean} 是否多选，默认true。
 *   select {Function(files, errors)} 当用户选择了文件后的回调函数。
 *     files 表示用户选择的文件，
 *     errors 表示不符合的文件，其结构为{file: File, code: int, message: String}，code的意义如下：
 *       0 文件大小超出限制。
 */
export default function (options) {
  options = Object.assign({
    accept: '',
    size: 0,
    multi: true
  }, options)
  const ID = 'TMP_FILE_INPUT'
  let input = document.getElementById(ID)
  if (input) {
    input.removeEventListener('change', input.onChangeEvent)
    input.remove()
  }
  if (Array.isArray(options.accept)) {
    options.accept = options.accept.join(',')
  }
  input = document.createElement('input')
  input.setAttribute('id', ID)
  input.setAttribute('type', 'file')
  input.setAttribute('accept', options.accept)
  if (options.multi) {
    input.setAttribute('multiple', 'multiple')
  }
  input.style.display = 'none'
  document.body.appendChild(input)
  input.onChangeEvent = function (e) {
    var files = e.target.files
    const success = []
    const error = []
    for (const file of files) {
      if (options.size !== 0 && file.size > options.size) {
        error.push({
          file: file,
          code: 0,
          message: `文件大小[${kit.str.fmtSize(file.size)}]超出了限制[${kit.str.fmtSize(options.size)}]。`
        })
      } else {
        success.push(file)
      }
    }
    options.select(success, error)
  }
  input.addEventListener('change', input.onChangeEvent)
  input.click()
}

export function readDataUrlFromFile (file, callback) {
  const fr = new FileReader()
  fr.onload = () => {
    callback && callback(fr.result)
  }
  fr.readAsDataURL(file)
}
