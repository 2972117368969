import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const listBusiness = defineApi((config) => {
  http.get(path('/config/business/list'), config)
})

export const updateBusinessEnabled = defineApi((config, type, enabled) => {
  config.params = { businessType: type, enabled: enabled }
  http.post(path('/config/business/update/enabled'), config)
})

export const updateBusinessPoint = defineApi((config, type, point) => {
  config.params = { businessType: type, point: point }
  http.post(path('/config/business/update/point'), config)
})

export const listPointProducts = defineApi((config) => {
  http.get(path('/config/point_product/list'), config)
})

export const updatePointProducts = defineApi((config, products) => {
  config.data = products
  http.post(path('/config/point_product/update'), config)
})

export const getVipServiceConfig = defineApi((config) => {
  http.get(path('/config/vip_service/get'), config)
})

export const updateVipServiceConfig = defineApi((config, vipServiceConfig) => {
  config.data = vipServiceConfig
  http.post(path('/config/vip_service/update'), config)
})

export const getCustomerLogin = defineApi((config) => {
  http.get(path('/config/customer_login/get'), config)
})

export const updateCustomerLogin = defineApi((config, data) => {
  config.data = data
  http.post(path('/config/customer_login/update'), config)
})

export const getStableDiffusionPromptText = defineApi((config) => {
  http.get(path('/config/sd_prompt_text/get'), config)
})

export const updateStableDiffusionPromptText = defineApi((config, data) => {
  config.data = data
  http.post(path('/config/sd_prompt_text/update'), config)
})
