<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" @click="$refs.modal.open()">创建计划</a-button>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="actions" slot-scope="row">
        <a-button size="small" @click="navDetail(row)">详情</a-button>
      </div>
      <div slot="image" slot-scope="row">
        <div class="image-item" :style="{'background-image': `url(${row.mainImage})`}"></div>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <create-modal ref="modal" @saved="reloadData()"></create-modal>

  </app-page>
</template>

<script>
import { ROUTE_AI_DEMO_PLAN, ROUTE_AI_DEMO_IMAGE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import CreateModal from './comp/create-modal'
import { listPlan } from '@/http/api/ai-demo-plan'


export default {
  components: { CreateModal },
  data () {
    return {
      routeName: ROUTE_AI_DEMO_PLAN,
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'actions' }, width: 80 },
        { title: '图片', scopedSlots: { customRender: 'image' }, width: 120 },
        { title: '标题', dataIndex: 'title' },
        { title: 'ComfyUI版本', dataIndex: 'workflowName' },
        { title: '创建人', dataIndex: 'userName' },
        { title: '创建时间', dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        title: null,
        userId: null
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      listPlan()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    navDetail (row) {
      this.$router.push({ name: ROUTE_AI_DEMO_IMAGE, params: { id: row.id } })
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style lang="less" scoped>

</style>
