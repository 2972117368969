<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      title="ComfyUI编辑"
      @save="onSave"
      @close="onClose"
      :width="1200"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type">
        <a-select v-model="form.type">
          <a-select-option v-for="item in businessOptions" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Workflow API" prop="workflowApi">
        <a-textarea
            v-model="form.workflowApi"
            :auto-size="{ minRows: 10, maxRows: 10 }"
        />
        <a-alert message="内容提示" type="info" show-icon>
          <div slot="description">
            <div class="font-mini" v-show="form.type === 'rtt'">
              图生图：内容里的这些变量会被系统替换成对应的值。
              <div><span class="text-danger">__IMAGE_PATH__</span>：原图</div>
              <div><span class="text-danger">__MASK_PATH__</span>：遮罩图</div>
              <div><span class="text-danger">__PROMPT_TEXT__</span>：正向提示词</div>
              <div><span class="text-danger">__NEG_PROMPT_TEXT__</span>：反向提示词</div>
              <div><span class="text-danger">__SEED__</span>：种子</div>
            </div>

            <div v-show="form.type === 'enlarge'">
              扩图：内容里的这些变量会被系统替换成对应的值。
              <div><span class="text-danger">__IMAGE__</span>：图片</div>
              <div><span class="text-danger">__LEFT__</span>：左扩张间距</div>
              <div><span class="text-danger">__TOP__</span>：上扩张间距</div>
              <div><span class="text-danger">__RIGHT__</span>：右扩张间距</div>
              <div><span class="text-danger">__BOTTOM__</span>：下扩张间距</div>
            </div>
          </div>
        </a-alert>
      </a-form-model-item>
      <a-form-model-item label="Current" prop="current">
        <a-switch v-model="form.current"></a-switch>
      </a-form-model-item>
    </a-form-model>
  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import { add, update } from '@/http/api/comfy-ui'

function buildParams () {
  return {
    id: null,
    title: null,
    workflowApi: null,
    current: false,
    type: null
  }
}
export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: buildParams(),
      businessOptions: this.$const.businessType.options
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
    },
    onClose () {
      this.model = null
    },
    onSave () {
      this.save()
    },
    save () {
      this.saveButtonLoading = true
      const api = this.model ? update : add
      api()
        .complete(() => (this.saveButtonLoading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.$emit('saved')
          this.$nextTick(() => (this.visible = false))
        })
        .send(this.form)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
