<template>
  <modal ref="m"
         title="Prompt模版编辑器"
         @ok="onOk"
         @closed="onClosed"
         :loading="buttonLoading"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="图片" v-show="templateGroup && templateGroup.type !== 'text'">
        <image-selector :file.sync="imageFile"
                        :default-image-url="defaultImageUrl"
                        @removeFile="handleRemoveImage"></image-selector>
      </a-form-model-item>
      <a-form-model-item label="标题" class="app_required-input" prop="title">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <div v-if="groupType === 'lora'">
        <a-form-model-item label="文件名" prop="content">
          <a-input v-model="form.content" placeholder="文件名包含后缀，如：mylora.safetensors"/>
        </a-form-model-item>
        <a-form-model-item label="模型URL" v-show="!model">
          <a-input-search v-model="modelDownload.downloadUrl" placeholder="请粘贴下载URL" @search="handleDownload">
            <a-button slot="enterButton" :loading="buttonLoading">开始下载</a-button>
          </a-input-search>
          <p style="font-size: 12px; color: #9a9999">
              下载期间，请不要关闭对话框，
              <span v-if="modelDownload.status === 2">正在下载 <span class="text-danger">{{modelDownload.percent}}%</span></span>
              <span v-else-if="modelDownload.status === 3" class="text-success">下载完成，请保存。</span>
              <span v-else-if="modelDownload.status === 4" class="text-danger">下载错误。</span>
          </p>
        </a-form-model-item>
      </div>
      <div v-else-if="groupType !== 'bg'">
        <a-form-model-item label="提示语" prop="content">
          <a-textarea
              v-model="form.content"
              :auto-size="{ minRows: 5, maxRows: 10 }"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
  </modal>
</template>

<script>
import { addTemplate, updateTemplate, downloadModel, getDownloadModelProgress } from '@/http/api/prompt-template'
import kit from '@/utils/kit'
import ImageSelector from '@/components/image-selector'

export default {
  components: { ImageSelector },
  data () {
    return {
      loading: false,
      form: {
        title: null,
        content: null
      },
      templateGroup: null,
      imageFile: null,
      model: null,
      /**
       * 更新操作的时候，对图片是什么样的操作。
       * remove: 移除图片
       * update: 更新图片
       * ignore: 忽略更新
       */
      imageUpdate: null,
      modelDownload: {
        intervalId: null,
        downloadUrl: null,
        /**
         * 1 等待
         * 2 正在下载
         * 3 下载完成
         * 4 下载错误
         */
        status: 1,
        percent: 0
      }
    }
  },
  computed: {
    defaultImageUrl () {
      if (this.model) {
        return this.model.image
      } else {
        return null
      }
    },
    groupType () {
      return this.templateGroup ? this.templateGroup.type : null
    },
    buttonLoading () {
      return this.loading || this.modelDownload.status === 2
    }
  },
  watch: {
    imageFile (file) {
      if (file) {
        this.imageUpdate = 'update'
      }
    }
  },
  methods: {
    open (group, model) {
      this.$refs.m.open()
      this.templateGroup = group
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
        this.imageUpdate = 'ignore'
      }
    },
    onClosed () {
      this.form.content = null
      this.form.title = null
      this.model = null
      this.imageFile = null
      this.templateGroup = null
      clearInterval(this.modelDownload.intervalId)
    },
    onOk () {
      const api = this.model ? updateTemplate : addTemplate
      const data = Object.assign({
        id: this.model ? this.model.id : null,
        templateGroupId: this.templateGroup.id,
        imageUpdate: this.imageUpdate
      }, this.form)
      this.loading = true
      api()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
          this.$nextTick(() => (this.$refs.m.close()))
        })
        .send(data, this.imageFile)
    },
    handleRemoveImage () {
      this.imageUpdate = 'remove'
    },
    handleDownload () {
      if (this.modelDownload.downloadUrl && this.form.content) {
        this.modelDownload.status = 2
        downloadModel().error(() => {}).send(this.modelDownload.downloadUrl, this.groupType, this.form.content)
        this.modelDownload.intervalId = setInterval(() => {
          this.checkDownloadProgress()
        }, 2000)
      } else {
        this.$message.warning('请填写下载地址和文件名。')
      }
    },
    checkDownloadProgress () {
      getDownloadModelProgress()
        .success(resp => {
          this.modelDownload.status = resp.data.status
          if (this.modelDownload.status === 2) {
            this.modelDownload.percent = resp.data.percent
          } else {
            clearInterval(this.modelDownload.intervalId)
          }
        })
        .send()
    }
  },
  beforeDestroy () {
    clearInterval(this.modelDownload.intervalId)
  }
}
</script>

<style lang="less" scoped>

</style>
