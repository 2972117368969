import defineApi from '../define-api'
import http from '../application-api'
import { userStore } from '@/store'
import path from '@/http/api/url-prefix'

/**
 * 发送登录请求，将服务端返回的用户信息保存到本地。
 * @type {Function}
 */
export const login = defineApi((config, username, password, code) => {
  config = Object.assign({
    params: {
      username: username,
      password: password,
      code: code
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post(path('/login'), config)
})

/**
 * 发送登出请求，请求成功后，移除本地登录用户信息，并新获取sessionId后再将回调函数交给外部。
 * @type {Function}
 */
export const logout = defineApi((config) => {
  const successCallback = config.success
  config.success = function (resp) {
    userStore.removeUserInfo()
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post(path('/logout'), config)
})

export const updatePassword = defineApi((config, oldPassword, newPassword) => {
  config.params = {
    oldPassword: oldPassword,
    newPassword: newPassword,
    logoutOnSuccess: false
  }
  http.post('/user/update/my_password', config)
})


/**
 * @param userQuery {UserQuery}
 */
export const list = defineApi((config, userQuery) => {
  config.params = userQuery
  return http.get(path('/user/list'), config)
})

/**
 * @param user {UserUpdate}
 * @return {User}
 */
export const addUser = defineApi((config, user) => {
  config.data = user
  http.post(path('/user/add'), config)
})

/**
 * @param user {UserUpdate}
 */
export const updateUser = defineApi((config, user) => {
  config.data = user
  http.post(path('/user/update'), config)
})

/**
 * @param [userId] {String}
 * @return {CurrentUserRole}
 */
export const getUserRoles = defineApi((config, userId) => {
  config.params = { userId: userId }
  http.get(path('/user/user_roles'), config)
})

export const getLoginValidateCode = defineApi(config => {
  http.get(path('/user/login/validate_code'), config)
})
