/**
 * @author jiangxingshang
 * @date 2022/12/7
 */

import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


/**
 * 获取所有系统可用的保护资源。
 * @return
 * {
 *   url: [
 *     {
 *       name: String,
 *       groups: [
 *         {
 *           id: String,
 *           name: String,
 *           resources: [ { name: String, path: String } ]
 *         }
 *       ]
 *     }
 *   ],
 *   ui: [ProtectResourceUi],
 *   menu: [ProtectResourceUi]
 * }
 */
export const listAvailableResources = defineApi((config) => {
  http.get(path('/protect_resource/available_resources'), config)
})

/**
 * 获取权限的已有分组名称列表。
 * @return {Array} string of array
 */
export const listGroupNames = defineApi((config) => {
  http.get(path('/protect_resource/all_group_names'), config)
})

/**
 * @param data {ProtectResourceUpdate}
 */
export const addProtectResource = defineApi((config, data) => {
  config.data = data
  http.post(path('/protect_resource/add'), config)
})

/**
 * @param data {ProtectResourceUpdate}
 */
export const updateProtectResource = defineApi((config, data) => {
  config.data = data
  http.post(path('/protect_resource/update'), config)
})

/**
 * @param id {String}
 */
export const deleteProtectResourceById = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/protect_resource/delete'), config)
})

/**
 * @param id
 * @return array of string
 */
export const getProtectResourceItemsById = defineApi((config, id) => {
  config.params = { id: id }
  http.get(path('/protect_resource/items/by_id'), config)
})


/**
 * 添加UI资源
 * @param protectResourceUi {ProtectResourceUi}
 */
export const addUi = defineApi((config, protectResourceUi) => {
  config.data = protectResourceUi
  http.post(path('/protect_resource/ui/add'), config)
})

/**
 * 修改UI资源
 * @param protectResourceUi {ProtectResourceUi}
 */
export const updateUi = defineApi((config, protectResourceUi) => {
  config.data = protectResourceUi
  http.post(path('/protect_resource/ui/update'), config)
})

/**
 * 删除UI资源
 */
export const deleteUi = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/protect_resource/ui/delete'), config)
})
