<template>
  <div>
    <a-button type="primary" @click="handleEditorOpen()">添加</a-button>

    <a-alert style="display: inline-block; margin-left: 20px;" message="加油包是提供给客户购买的点数产品。" type="info" show-icon />

    <a-table
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="handleEditorOpen(row)" icon="edit"></a-button>
        <a-button size="small" @click="handleDelete(row)" icon="delete" type="danger"></a-button>
      </div>
    </a-table>

    <modal ref="modal" title="编辑" :loading="formLoading" @ok="onEditorConfirm" @closed="onEditorClosed">
      <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="点数" class="app_required-input">
          <a-input-number v-model="form.point" :min="0"/>
        </a-form-model-item>
        <a-form-model-item label="价格" class="app_required-input">
          <a-input-number v-model="form.price" :min="0"/>
        </a-form-model-item>
        <a-form-model-item label="有效天数" class="app_required-input">
          <a-input-number v-model="form.days" :min="0"/>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>
import { listPointProducts, updatePointProducts } from '@/http/api/config'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', width: 110, scopedSlots: { customRender: 'action' } },
        { title: '点数', dataIndex: 'point' },
        { title: '价格', dataIndex: 'price' },
        { title: '有效天数', dataIndex: 'days' }
      ],
      form: {
        id: null,
        point: 0,
        price: 0,
        days: 7
      },
      formLoading: false
    }
  },
  methods: {
    show (firstShow) {
      if (firstShow) {
        this.loadData()
      }
    },
    hide () {
    },
    loadData () {
      this.dataLoading = false
      listPointProducts()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          resp.data.sort((a, b) => a.price - b.price)
          this.dataList = resp.data
        })
        .send()
    },
    handleEditorOpen (row) {
      if (row) {
        this.form.id = row.id
        this.form.days = row.days
        this.form.price = row.price
        this.form.point = row.point
      }
      this.$refs.modal.open()
    },
    onEditorConfirm () {
      const arr = [...this.dataList]
      const data = Object.assign({}, this.form)
      if (!data.id) {
        data.id = kit.str.id()
        arr.push(data)
      }
      kit.arr.pushOrReplace(arr, data)
      this.formLoading = true
      updatePointProducts()
        .complete(() => (this.formLoading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.loadData()
          this.$nextTick(() => (this.$refs.modal.close()))
        })
        .send(arr)
    },
    onEditorClosed () {
      this.form.id = null
      this.form.price = 0
      this.form.point = 0
      this.form.days = 7
    },
    handleDelete (row) {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          const arr = [...this.dataList]
          kit.arr.removeItem(arr, row)
          this.dataLoading = true
          updatePointProducts()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.dataList = arr
              this.$message.success('删除成功。')
              this.loadData()
            })
            .send(arr)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
