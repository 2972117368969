<!--
<image-selector :file.sync="file"></image-selector>
-->

<template>
  <div :style="{width: width, height: height, 'background-image': `url(${imageUrl})`}" class="light-image-selector">
    <div class="toolbar">
      <slot name="toolbar"></slot>
      <a-button size="small" shape="circle" icon="edit" @click="selectImage" :disabled="disabled" />
      <a-button size="small" type="danger" shape="circle" icon="delete" @click="removeImage" :disabled="disabled" />
    </div>
    <span v-show="!imageUrl">请选择 {{title}}</span>
  </div>
</template>

<script>
import selectFiles, { readDataUrlFromFile } from '@/utils/select-file'

export default {
  props: {
    title: { type: String, default: '图片' },
    accepts: { type: String, default: 'image/jpg,image/jpeg,image/png' },
    maxSize: { type: Number, default: 1024 * 1024 * 2 },
    disabled: { type: Boolean, default: false },
    width: { type: String, default: '100%' },
    height: { type: String, default: '250px' }
  },
  computed: {
  },
  data () {
    return {
      imageUrl: null,
      file: null
    }
  },
  watch: {
  },
  methods: {
    selectImage () {
      selectFiles({
        accept: this.accepts.split(','),
        size: this.maxSize,
        multi: false,
        select: (files, e) => {
          if (e.length > 0) {
            this.$message.error('【' + e[0].file.name + '】' + e[0].message)
          } else if (files.length > 0) {
            this.file = files[0]
            readDataUrlFromFile(this.file, dataUrl => (this.imageUrl = dataUrl))
          }
        }
      })
    },
    removeImage () {
      this.file = null
      this.imageUrl = null
    },
    getFile () {
      return this.file
    },
    getImageDataUrl () {
      return this.imageUrl
    },
    setImageUrl (url) {
      this.file = null
      this.imageUrl = url
    }
  }
}
</script>

<style lang="less" scoped>
.light-image-selector {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #5b5b5b;

  .toolbar {
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: right;
  }
}
</style>
