<template>
  <app-page :route-name="routeName" :use-tcb-layout="false" :page-loading="pageLoading">
    <div class="wrapper">
      <panel class="group-side" title="分组" width="350px">
        <a-button slot="toolbar" type="link" @click="$refs.group.open()">添加</a-button>

        <div>
          <div v-for="g in groups" :key="g.id" :class="{'disabled': !g.enabled, 'selected': g === selectedGroup}" class="group-item">
            <div class="main-content" @click="onGroupSelected(g)">
              <div class="title">{{g.title}}</div>
              <div class="font-mini text-muted">
                <span v-if="g.type === 'text'">文字</span>
                <span v-else-if="g.type === 'lora'">Lora</span>
                <span v-else-if="g.type === 'bg'">背景风格</span>
                <span v-else>文字+图片</span>
                /
                <span v-if="g.multiSelect">多选</span>
                <span v-else>单选</span>
              </div>
            </div>
            <div class="buttons">
              <a-button icon="edit" size="small" @click="$refs.group.open(g)"></a-button>
              <a-button icon="delete" size="small" type="danger" @click="handleDeleteGroup(g)"></a-button>
            </div>
          </div>
        </div>
      </panel>

      <panel class="item-side" title="模版">
        <a-button slot="toolbar" type="link" :disabled="!selectedGroup" @click="$refs.temp.open(selectedGroup)">添加</a-button>
        <div class="text-muted font-mini content-center" style="margin-top: 20px;" v-if="!selectedGroup">请选择分组</div>
        <div class="text-muted font-mini content-center" style="margin-top: 20px;" v-if="selectedGroup && templates.length === 0">没有任何模版。</div>
        <loading :loading="templateLoading"></loading>

        <div class="template-container">
          <div v-for="item in templates" :key="item.id" class="template-item" :class="{'cover': !onlyText}">
            <div class="content">
              <div class="image-item" :style="{'background-image': `url(${item.image})`}" v-if="!onlyText"></div>
              <div class="title">{{item.title}}</div>
            </div>
            <div class="buttons">
              <a-button size="small" icon="edit" @click="$refs.temp.open(selectedGroup, item)"></a-button>
              <a-button size="small" icon="delete" type="danger" @click="handleDeleteTemplate(item)"></a-button>
            </div>
          </div>
        </div>
      </panel>
    </div>

    <group-editor ref="group" @saved="onGroupSaved"></group-editor>
    <template-editor ref="temp" @saved="onTemplateSaved"></template-editor>
  </app-page>
</template>

<script>
import { ROUTE_PROMPT_TEMPLATE } from '@/router/router-constants'
import GroupEditor from './comp/group-editor'
import TemplateEditor from './comp/item-editor'
import { listAll, deleteTemplateGroup, listTemplateByGroupId, deleteTemplate } from '@/http/api/prompt-template'
import kit from '@/utils/kit'

export default {
  components: { GroupEditor, TemplateEditor },
  data () {
    return {
      routeName: ROUTE_PROMPT_TEMPLATE,
      pageLoading: false,
      dataLoading: false,
      groups: [],
      selectedGroup: null,
      templates: [],
      templateLoading: false
    }
  },
  computed: {
    onlyText () {
      return this.selectedGroup ? this.selectedGroup.type === 'text' : false
    }
  },
  watch: {
    selectedGroup (g) {
      if (g) {
        this.loadTemplates()
      } else {
        this.templates = []
      }
    }
  },
  methods: {
    loadTemplateGroups () {
      listAll()
        .success(resp => {
          this.groups = resp.data
          if (this.groups.length > 0) {
            this.selectedGroup = this.groups[0]
          }
        })
        .send()
    },
    loadTemplates () {
      if (!this.selectedGroup) {
        return
      }
      this.templateLoading = true
      listTemplateByGroupId()
        .complete(() => (this.templateLoading = false))
        .success(resp => {
          this.templates = resp.data
        })
        .send(this.selectedGroup.id)
    },
    onGroupSaved (group) {
      kit.arr.pushOrReplace(this.groups, group)
      this.groups.sort((a, b) => a.position - b.position)
    },
    onGroupSelected (group) {
      this.selectedGroup = group
    },
    handleDeleteGroup (group) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${group.title}】吗？`,
        onOk: () => {
          this.pageLoading = true
          deleteTemplateGroup()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              kit.arr.removeItem(this.groups, group)
            })
            .send(group.id)
        }
      })
    },
    onTemplateSaved (temp) {
      kit.arr.pushOrReplace(this.templates, temp)
    },
    handleDeleteTemplate (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.title}】吗？`,
        onOk: () => {
          this.templateLoading = true
          deleteTemplate()
            .complete(() => (this.templateLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              kit.arr.removeItem(this.templates, item)
            })
            .send(item.id)
        }
      })
    }
  },
  mounted () {
    this.loadTemplateGroups()
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
}

.group-side {
  .group-item + .group-item {
    margin-top: 8px;
  }
  .group-item {
    display: flex;
    padding: 8px;
    cursor: pointer;
    &:not(:last-of-type) {
      border-bottom: solid 1px #eee;
    }
    .main-content {
      flex: 1;
      .title {
        font-size: 14px;
      }
    }
    &.disabled .title {
      text-decoration: line-through;
      color: #a94442;
    }
    &.selected {
      background-color: #f2f2f2;
    }
  }
}

.item-side {
  flex: 1;
  margin-left: 20px;
}

.template-container {
  display: flex;
  flex-wrap: wrap;
  .template-item {
    position: relative;
    @size: 200px;
    width: @size;
    margin: 10px;
    .image-item {
      width: @size;
      height: @size;
    }
    .title {
      text-align: center;
      font-size: 13px;
      margin: 8px 0;
    }
    .buttons {
      text-align: center;
      visibility: hidden;
    }
    &:hover .buttons {
      visibility: visible;
    }

    &:not(.cover) {
      width: auto;
      min-width: 100px;
      padding: 4px 16px;
      border: solid 1px #484848;
      border-radius: 20px;
      overflow: hidden;
      .buttons {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.3);
      }
    }
  }
}
</style>
