<template>
    <a-layout-header class="app-header">

      <div class="left-bar" @click="$router.push({name: routeDashboard})">
        <img class="logo" :src="logoUrl" v-if="logoUrl" />
        <div class="app-name">{{ appName }}</div>
        <div class="app-version" v-show="version">（{{version}}）</div>
      </div>
      <div class="right-bar">
        <div class="bar-item user-profile" @click.stop.prevent="handleUserProfileClick">
          <span class="username">
            <span v-if="dept">（{{dept}}）</span>{{name}}
          </span>
          <a-icon type="down" style="font-size: 12px;" />
          <ul class="menu" v-show="menuVisible">
            <li class="menu-item" @click="passwordModalVisible = true">修改密码</li>
            <li class="menu-item" @click="logout">退出系统</li>
          </ul>
        </div>
      </div>

      <password-modal ref="pwd" :visible.sync="passwordModalVisible"></password-modal>
    </a-layout-header>
</template>

<script>
import { logout } from '@/http/api/user'
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from '@/router/router-constants'
import { userStore, appStore } from '@/store'
import kit from '@/utils/kit'
import PasswordModal from '@/components-business/password-modal'

export default {
  components: { PasswordModal },
  data () {
    return {
      passwordModalVisible: false,
      routeDashboard: ROUTE_DASHBOARD,
      menuVisible: false
    }
  },
  computed: {
    ...(userStore.mapState(['name', 'dept'])),
    ...(appStore.mapState({
      version: 'version',
      logoUrl: 'logoUrl',
      appName: 'name'
    }))
  },
  watch: {
  },
  filters: {
  },
  methods: {
    logout () {
      this.$confirm({
        title: '提示',
        content: '确定要退出系统吗？',
        onOk: () => {
          logout()
            .success(() => {
              this.$router.push({ name: ROUTE_LOGIN })
            })
            .send()
        }
      })
    },
    handleUserProfileClick () {
      if (!this.menuVisible) {
        kit.dom.addEvent(document.documentElement, 'click', this.hideMenu)
      }
      this.menuVisible = !this.menuVisible
    },
    hideMenu () {
      this.menuVisible = false
      kit.dom.removeEvent(document.documentElement, 'click', this.hideMenu)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  @height: 64px;
  .app-header {
    display: flex;
    flex-direction: row;
    padding: 0 20px !important;
    height: @height;
    line-height: @height;
    background-color: #2a2a2a;
    color: #fff;
    .left-bar {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      .logo {
        max-width: 120px;
        max-height: 50px;
        margin-right: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .app-name {
        font-size: 16px;
      }
      .app-version {
        font-size: 12px;
      }
    }
    .right-bar {
      position: relative;
      line-height: @height;
      height: @height;
      .bar-item {
        float: right;
        position: relative;
        padding: 0 10px;
        font-size: 14px;
        color: #efefef;
        cursor: pointer;
        &:hover {
          background-color: #393939;
        }
      }
    }
  }

  .bar-item.user-profile {
    .username {
      margin-right: 10px;
    }

    .menu {
      position: absolute;
      margin: 0;
      padding: 0;
      top: @height;
      right: 0;
      z-index: 500;
      min-width: 150px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #393939;
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      list-style-type: none;
      overflow: hidden;
      .split-line {
        height: 1px;
        padding: 0;
        margin: 0 15px;
        background-color: #5c5c5c;
      }
      .menu-item {
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0 15px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: #414141;
        }
        &:active {
          background-color: #464646;
        }
      }
    }
  }

  .bar-item {
    .text {
      font-size: 13px;
      margin-left: 3px;
    }
    i {
      position: relative;
    }
    .badge {
      display: inline-block;
      position: absolute;
      top: -12px;
      left: -12px;
      padding: 3px 4px;
      min-width: 12px;
      text-align: center;
      border-radius: 10px;
      font-size: 12px !important;
      color: #fff;
      background-color: #df5040;
    }
  }
</style>
