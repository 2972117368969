/**
 * @author jiangxingshang
 * @date 2022/12/6
 */
export default class PageSize {
  constructor () {
    this.start = 0
    this.limit = 0
  }
}
