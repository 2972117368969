<template>
  <div>
    <a-table
        size="middle"
        rowKey="type"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :loading="dataLoading"
    >

      <div slot="point" slot-scope="consumePoint, row">
        <a-input-number v-model="row.consumePoint" :min="1" :max="999" :step="1"></a-input-number>
        <a-button type="primary" style="margin-left: 10px;" :disabled="row.oldConsumePoint === row.consumePoint" @click="handleUpdatePoint(row)">更新</a-button>
      </div>


      <div slot="enabled" slot-scope="enabled, row">
        <a-switch v-model="row.enabled" checked-children="开启" un-checked-children="关闭" @change="handleEnabledChanged(row, $event)"></a-switch>
      </div>
    </a-table>
  </div>
</template>

<script>
import { listBusiness, updateBusinessPoint, updateBusinessEnabled } from '@/http/api/config'

export default {
  data () {
    return {
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '业务', dataIndex: 'name' },
        { title: '当前运行任务', width: 200, dataIndex: 'runningExecutionTotal' },
        { title: '消耗点数', width: 350, dataIndex: 'consumePoint', scopedSlots: { customRender: 'point' } },
        { title: '业务状态', width: 250, dataIndex: 'enabled', scopedSlots: { customRender: 'enabled' } }
      ]
    }
  },
  methods: {
    show (firstShow) {
      if (firstShow) {
        this.loadData()
      }
    },
    hide () {
    },
    loadData () {
      this.dataLoading = false
      listBusiness()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          for (const item of resp.data) {
            item.oldConsumePoint = item.consumePoint
          }
          this.dataList = resp.data
        })
        .send()
    },
    handleEnabledChanged (row, enabled) {
      this.pageLoading = true
      updateBusinessEnabled()
        .complete(success => {
          this.pageLoading = false
          if (!success) {
            row.enabled = !enabled
          }
        })
        .send(row.type, enabled)
    },
    handleUpdatePoint (row) {
      this.$confirm({
        title: '确认',
        content: `业务【${this.$const.businessType.text[row.type]}】确定要更新为 [${row.consumePoint}] 吗？`,
        onOk: () => {
          this.pageLoading = true
          updateBusinessPoint()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('更新成功。')
              row.oldConsumePoint = row.consumePoint
            })
            .send(row.type, row.consumePoint)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
