<template>
  <app-page :route-name="routeName" :use-tcb-layout="false" :page-loading="pageLoading">

    <div slot="toolbar">
      <div class="status-total">
        <span style="color: #5b5b5b">等待处理：</span>{{statusTotal.wait}}
        <span style="color: #3c6d9f">正在处理：</span>{{statusTotal.progress}}
        <span style="color: #3C763C">成功：</span>{{statusTotal.success}}
        <span style="color: #a94442">失败：</span>{{statusTotal.fail}}
        <span style="color: #ce7e3c">超时：</span>{{statusTotal.timeout}}
      </div>

      <a-button type="primary" @click="handleStartPlan" v-show="!running && statusTotal.wait > 0">开始AI测图</a-button>
      <a-button @click="handleStopPlan" v-show="running">结束AI测图</a-button>
    </div>

    <div class="tip-message" v-show="running">正在测图，请不要离开当前页面。</div>

    <div class="wrapper">
      <div class="plan-info" v-if="plan">
        <div class="font-bold font-large" style="margin-bottom: 20px; border-bottom: solid 1px #eee; padding-bottom: 10px;">{{plan.title}}</div>
        <div style="text-align: center;">
          <div class="image-item" :style="{'background-image': `url(${plan.mainImage})`}" style="margin-right: 10px;"></div>
          <div class="image-item" :style="{'background-image': `url(${plan.maskImage})`}"></div>
        </div>

        <a-divider orientation="left">正向词组</a-divider>

        <ul class="list-group">
          <li class="list-group-item" v-for="(strList, idx1) in plan.prompt" :key="idx1">
            <div v-for="(s, idx2) in strList" :key="idx2">{{idx2+1}}、{{s}}</div>
          </li>
        </ul>

        <div v-if="plan.negPrompt">
          <a-divider orientation="left">负向提示词</a-divider>
          {{plan.negPrompt}}
        </div>

      </div>

      <div class="image-list">
        <div class="image"
             v-for="img in images"
             :key="img.id"
             :style="{'background-image': `url(${img.image})`}"
             :class="'status-' + img.status"
             @click="handleOpenImage(img)"
        >
          <span class="number">{{img.number}}</span>
          <span class="execution-time">{{Math.round(img.executionTime / 1000)}}s</span>


          <a-tooltip v-if="img.status === 'fail'">
            <template slot="title">
              {{img.statusMessage}}
            </template>
            <span class="status-text">{{statusTextMap[img.status]}}</span>
          </a-tooltip>
          <span v-else class="status-text">
            {{statusTextMap[img.status]}}
            <a-icon type="loading" v-if="img.status === 'progress'" />
          </span>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_AI_DEMO_IMAGE } from '@/router/router-constants'
import {
  getPlanById,
  listImageByPlanId,
  getPlanRunningStatus,
  startPlan,
  shutdownPlan,
  submitPlanImage,
  getImageStatusByPlan
} from '@/http/api/ai-demo-plan'

const MAX_RUNNING_IMAGE_TOTAL = 10

export default {
  data () {
    return {
      routeName: ROUTE_AI_DEMO_IMAGE,
      plan: null,
      images: [],
      pageLoading: false,
      statusTextMap: {
        wait: '等待处理',
        progress: '正在处理',
        fail: '失败',
        success: '成功',
        timeout: '超时'
      },
      running: false,
      clearGetImageStatusId: null
    }
  },
  watch: {
  },
  computed: {
    planId () {
      return this.$route.params.id
    },
    statusTotal () {
      const map = { wait: 0, progress: 0, fail: 0, success: 0, timeout: 0 }
      for (const img of this.images) {
        switch (img.status) {
          case 'wait': map.wait++; break
          case 'progress': map.progress++; break
          case 'fail': map.fail++; break
          case 'success': map.success++; break
          case 'timeout': map.timeout++; break
        }
      }
      return map
    }
  },
  methods: {
    loadImages (callback) {
      // this.pageLoading = true
      listImageByPlanId()
        // .complete(() => (this.pageLoading = false))
        .success(resp => {
          this.images = resp.data
          if (callback) {
            callback()
          }
        })
        .send(this.planId)
    },
    handleStartPlan () {
      this.pageLoading = true
      startPlan()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.running = true
          this.startIntervalToGetImageStatus()
        })
        .send(this.planId)
    },
    handleStopPlan () {
      this.pageLoading = true
      shutdownPlan()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.running = false
          clearInterval(this.clearGetImageStatusId)
          this.loadImages()
        })
        .send(this.planId)
    },
    startIntervalToGetImageStatus () {
      this.clearGetImageStatusId = setInterval(() => {
        this.loadImages(() => {
          if (this.statusTotal.wait === 0 && this.statusTotal.progress === 0) {
            clearInterval(this.clearGetImageStatusId)
            this.running = false
          }
        })
      }, 4000)
    },
    handleOpenImage (img) {
      if (img.status === 'success') {
        this.$imageModal.open([
          {
            url: img.image,
            title: img.prompt
          }
        ])
      }
    }
  },
  mounted () {
    getPlanById()
      .success(resp => {
        resp.data.prompt = JSON.parse(resp.data.prompt)
        this.plan = resp.data
      })
      .send(this.planId)

    this.loadImages(() => {
      getPlanRunningStatus().success(resp => {
        this.running = resp.data
        if (this.running) {
          this.startIntervalToGetImageStatus()
        }
      }).send(this.planId)
    })
  },
  beforeDestroy () {
    clearInterval(this.clearGetImageStatusId)
  }
}
</script>

<style lang="less" scoped>

.status-total {
  display: inline-block;
  margin-right: 30px;
  font-size: 12px;
  span {
    margin-left: 10px;
  }
}

.tip-message {
  text-align: center;
  background-color: #cc8a17;
  color: #fff;
  width: 300px;
  border-radius: 30px;
  margin: 10px auto;
  padding: 10px 0;
}

.wrapper {
  display: flex;
}

.plan-info {
  width: 300px;
  padding: 10px;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin-right: 20px;

  .image-item {
    width: 130px;
    height: 240px;
    display: inline-block;
  }
}

.image-list {
  flex: 1;
  flex-wrap: wrap;
  .image {
    display: inline-block;
    position: relative;
    width: 170px;
    height: 220px;
    margin: 8px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    .status-text {
      display: inline-block;
      font-size: 12px;
      padding: 8px 15px;
      border-radius: 20px;
      border: solid 1px #ddd;
      margin-top: 90px;
    }
    &.status-progress .status-text {
      border-color: #3c6d9f;
      color: #3c6d9f;
    }
    &.status-fail .status-text {
      border-color: #d72e34;
      color: #d72e34;
    }
    &.status-timeout .status-text {
      border-color: #ce7e3c;
      color: #ce7e3c;
    }
    &.status-success .status-text {
      visibility: hidden;
    }

    .number {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #2b2b2b;
      color: #fff;
      padding: 3px 10px;
      border-bottom-right-radius: 10px;
      font-size: 12px;
    }
    .execution-time {
      background-color: #ad5050;
      color: #fff;
      padding: 2px 10px;
      border-radius: 2px;
      position: absolute;
      z-index: 10;
      right: 0;
      font-size: 12px;
    }
  }
}
</style>
