import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

export const list = defineApi((config, params) => {
  config.params = params
  http.get(path('/ai_log/list'), config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get(path('/ai_log/get/by_id'), config)
})
