<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.editor.open()">添加</a-button>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="$refs.editor.open(row)">编辑</a-button>
        <a-button size="small" type="danger" @click="handleDelete(row)">删除</a-button>
      </div>

      <status slot="current" slot-scope="current" :status="current" enabled-text="使用中" disabled-text="N"></status>
    </a-table>

    <comfy-ui-editor ref="editor" @saved="loadData()"></comfy-ui-editor>

  </app-page>
</template>

<script>
import { ROUTE_COMFY_UI } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import ComfyUiEditor from './comp/comfy-ui-editor'
import { listAll, deleteById } from '@/http/api/comfy-ui'

export default {
  components: { ComfyUiEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_COMFY_UI,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 80, scopedSlots: { customRender: 'action' } },
        { title: '标题', width: 150, dataIndex: 'title' },
        { title: '类型', width: 150, dataIndex: 'type' },
        { title: 'Current', width: 100, dataIndex: 'current', scopedSlots: { customRender: 'current' } },
        { title: '创建时间', width: 150, dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listAll()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send()
    },
    handleDelete (row) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${row.title}】吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteById()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadData()
            })
            .send(row.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
