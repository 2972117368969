<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="手机">
            <a-input v-model="searchForm.phone" :allowClear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册日期">
            <a-range-picker v-model="searchForm.dates" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
          </a-form-model-item>
          <a-form-model-item label="剩余点数">
            <a-input-number v-model="searchForm.minPoint" :min="0" style="width: 70px;"></a-input-number>
            ~
            <a-input-number v-model="searchForm.maxPoint" :min="0" style="width: 70px;"></a-input-number>
          </a-form-model-item>
          <a-form-model-item label="消耗点数">
            <a-input-number placeholder="消耗点大于等于" v-model="searchForm.consumePoint" :min="0"></a-input-number>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="point" slot-scope="point, row">
        {{point}}
        <a-button type="link" @click="handleChargedModalOpen(row)">充值</a-button>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <modal ref="charged" title="充值" @ok="onChargedModalOk" @closed="onChargedModalClosed">
      <p class="text-muted font-mini">正在为{{chargedModal.phone}}充值，剩余点数：{{chargedModal.leftPoint}}</p>
      <a-input-number v-model="chargedModal.value" :min="1" :max="9999" :step="100" style="width: 200px;"></a-input-number>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_CUSTOMER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { list, pointCharge } from '@/http/api/customer'


export default {
  data () {
    return {
      routeName: ROUTE_CUSTOMER,
      dataColumns: [
        { title: '手机号', dataIndex: 'phone' },
        { title: '剩余点数', dataIndex: 'point', scopedSlots: { customRender: 'point' } },
        { title: '消费点数', dataIndex: 'consumePointAmount' },
        { title: '任务执行数', dataIndex: 'runTaskTotal' },
        { title: '注册日期', dataIndex: 'createDate' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        phone: null,
        dates: [],
        minPoint: 0,
        maxPoint: 0,
        consumePoint: 0
      },
      chargedModal: {
        phone: null,
        leftPoint: 0,
        value: 0,
        loading: false
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      if (p.dates.length === 2) {
        p.startDate = p.dates[0]
        p.endDate = p.dates[1]
      }
      delete p.dates
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    onChargedModalClosed () {
      this.chargedModal.phone = null
      this.chargedModal.leftPoint = 0
    },
    onChargedModalOk () {
      const id = this.dataList.find(item => item.phone === this.chargedModal.phone).id
      this.$confirm({
        title: '确认',
        content: `确定要为【${this.chargedModal.phone}】充值 ${this.chargedModal.value} 点数吗？`,
        onOk: () => {
          this.chargedModal.loading = true
          pointCharge()
            .complete(() => (this.chargedModal.loading = false))
            .success(() => {
              this.$message.success('充值成功。')
              this.loadData()
              this.$nextTick(() => (this.$refs.charged.close()))
            })
            .send(id, this.chargedModal.value)
        }
      })
    },
    handleChargedModalOpen (row) {
      this.chargedModal.phone = row.phone
      this.chargedModal.leftPoint = row.point
      this.chargedModal.value = 1
      this.$refs.charged.open()
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
