<template>
  <div class="panel" :style="panelStyle">
    <div class="panel-head">
      <div class="title text-overflow">
        <div v-if="$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else>{{title}}</span>
      </div>
      <div class="toolbar">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <div class="panel-body">
      <slot></slot>
    </div>
    <div class="panel-foot" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'panel',
  props: {
    title: { type: String, default: 'Panel' },
    width: { type: String, default: '300px' }
  },
  computed: {
    panelStyle () {
      if (this.width === 'full') {
        return { flex: 1 }
      } else {
        return { width: this.width }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.panel {
  display: flex;
  flex-direction: column;
  border: solid 1px #e8e8e8;
  background-color: #fff;
}
.panel-head {
  display: flex;
  height: 45px;
  min-height: 45px;
  line-height: 45px;
  padding: 0 14px;
  border-bottom: solid 1px #e8e8e8;
  .title {
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.panel-foot {
  height: 50px;
  min-height: 50px;
  line-height: 50px;
  padding: 0 14px;
  border-top: solid 1px #e8e8e8;
  text-align: center;
}

.panel-body {
  flex: 1;
  overflow-y: auto;
  padding: 14px;
  position: relative;
}
</style>
