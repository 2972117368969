<template>
  <div class="panel login-panel">

    <a-form-model @submit="login()" @submit.native.prevent :label-col="{span: 4}" :wrapper-col="{span: 18}">
      <a-form-model-item label="账号">
        <a-input v-model="username" allow-clear size="large" @keyup.enter="login">
          <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="密码">
        <a-input v-model="password" type="password" size="large" @keyup.enter="login">
          <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="验证码">
        <a-input v-model="code" type="code" size="large" :max-length="4" @keyup.enter="login">
          <img slot="suffix" :src="loginValidateCodeImage" @click="refreshValidateCode" style="height: 35px;" :style="{'cursor': loginValidateCodeImageLoading ? 'wait' : 'pointer'}">
        </a-input>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 18, offset: 4 }">
        <a-button type="primary" @click="login" block :loading="loading">{{loginButtonText}}</a-button>
      </a-form-model-item>
    </a-form-model>

  </div>
</template>

<script>
import { login, getLoginValidateCode } from '@/http/api/user'

export default {
  data () {
    return {
      loginButtonText: '登录系统',
      loading: false,
      username: null,
      password: null,
      code: null,
      loginValidateCodeImage: null,
      loginValidateCodeImageLoading: false
    }
  },
  computed: {
  },
  methods: {
    getLoginUserDashboard () {
      return this.$userStore.getLoginUserDashboard()
    },
    login () {
      this.loading = true
      login()
        .complete(() => {
          this.loading = false
        })
        .success(() => {
          localStorage.setItem('username', this.username)
          if (this.$route.query.from) {
            this.$router.push(this.$route.query.from)
          } else {
            this.$router.push({ name: this.getLoginUserDashboard() })
          }
        })
        .send(this.username, this.password, this.code)
    },
    clearUsername () {
      this.username = ''
      localStorage.removeItem('username')
    },
    refreshValidateCode () {
      if (!this.loginValidateCodeImageLoading) {
        this.loginValidateCodeImageLoading = true
        getLoginValidateCode()
          .complete(() => (this.loginValidateCodeImageLoading = false))
          .success(resp => {
            this.loginValidateCodeImage = resp.data
          })
          .send()
        this.code = ''
      }
    }
  },
  mounted () {
    this.refreshValidateCode()
    this.username = localStorage.getItem('username')
  }
}
</script>

<style lang="less" scoped>
</style>
