<template>
  <a-menu
      v-if="createMenu"
      mode="inline"
      :default-open-keys="defaultOpenKeys"
      :selected-keys="activeMenuId"
      :style="{ height: '100%', borderRight: 0 }"
      class="app-menu"
  >
    <template v-for="menu in menus">
      <a-sub-menu v-if="menu.menus && menu.menus.length > 0 && menu.visible" :key="menu.id">
        <span slot="title" style="font-weight: bold;">
          <a-icon :type="menu.icon" v-if="menu.icon" />
          {{ menu.title }}
        </span>
        <a-menu-item v-for="item in menu.menus" :key="item.id" @click="forward(item)" v-if="item.visible">
          {{item.title}}
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item :key="menu.id" @click="forward(menu)" v-else-if="menu.visible">
        {{ menu.title }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import menuData from './menu-data'
import { userStore } from '@/store'

export default {
  data () {
    return {
      menus: this.initMenus(menuData),
      createMenu: false
    }
  },
  computed: {
    activeMenuId () {
      const menuId = this.$store.state.activeMenuId
      return menuId ? [menuId] : []
    },
    ...(userStore.mapState({
      isLogin: 'isLogin',
      userType: 'type'
    })),
    defaultOpenKeys () {
      const found = this.menus.find(m => m.visible)
      return found ? [found.id] : []
    }
  },
  watch: {
    isLogin: {
      handler: function () {
        if (this.isLogin) {
          this.updateMenuVisible(userStore.state.menuList)
          this.createMenu = true
        }
      },
      immediate: true
    }
  },
  methods: {
    initMenus (menus) {
      for (const m of menus) {
        if (m.menus && m.menus.length > 0) {
          m.visible = false
          // is group
          for (const mm of m.menus) {
            mm.visible = false
          }
        } else {
          m.visible = false
        }
      }
      return menus
    },
    /**
     * @param userMenus {Array} 当前登录用户拥有的菜单id
     */
    updateMenuVisible (userMenus) {
      const map = {
        all: userMenus.length === 1 && userMenus[0] === '_all'
      }
      for (const m of userMenus) {
        map[m] = true
      }
      for (const m of this.menus) {
        if (m.menus && m.menus.length > 0) {
          if (map.all || map[m.id]) {
            m.visible = true
            for (const mm of m.menus) {
              mm.visible = true
            }
          } else {
            for (const mm of m.menus) {
              mm.visible = map.all || map[mm.id]
              if (mm.visible) {
                m.visible = true
              }
            }
          }
        } else {
          m.visible = map.all || map[m.id]
        }
      }
    },
    forward (menu) {
      if (menu.click) {
        menu.click()
      } else {
        this.$router.push({ name: menu.route || menu.id })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less">
.app-menu {
  overflow-y: auto;
  overflow-x: hidden;
  // 隐藏滚动条
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  border-right: solid 1px #e2e2e2 !important;

  .ant-menu-item-selected {
    background-color: #f6f6f6 !important;
    color: #2a2a2a !important;
  }
  .ant-menu-item::after {
    border-color: #2b2b2b !important;
  }
}
</style>
