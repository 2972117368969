/**
 * @author jiangxingshang
 * @date 2022/12/7
 */

import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const listAllRoles = defineApi((config) => {
  http.get(path('/role/list/all'), config)
})

/**
 * @param role {Role}
 */
export const addRole = defineApi((config, role) => {
  config.data = role
  http.post(path('/role/add'), config)
})

/**
 * @param role {Role}
 */
export const updateRole = defineApi((config, role) => {
  config.data = role
  http.post(path('/role/update'), config)
})

/**
 * @param id {String}
 */
export const deleteRole = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/role/delete'), config)
})

/**
 * @param id {String} 角色Id
 * @return {CurrentRoleProtectResource} { groupName: [CurrentRoleProtectResource] }
 */
export const getRoleProtectResources = defineApi((config, id) => {
  config.params = { roleId: id }
  http.get(path('/role/role_protect_resources'), config)
})

/**
 * 为角色分配或移除权限。
 * @param roleId
 * @param protectResourceId
 * @param apply 'apply' 表示分配权限，'remove' 表示移除权限
 */
export const applyProtectResource = defineApi((config, roleId, protectResourceId, apply) => {
  config.params = {
    roleId: roleId,
    protectResourceId: protectResourceId,
    apply: apply
  }
  http.post(path('/role/protect_resource/apply'), config)
})

