import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

export const listAll = defineApi((config, params) => {
  config.params = params
  http.get(path('/prompt_template/group/list/all'), config)
})

export const addTemplateGroup = defineApi((config, data) => {
  config.data = data
  http.post(path('/prompt_template/group/add'), config)
})

export const updateTemplateGroup = defineApi((config, data) => {
  config.data = data
  http.post(path('/prompt_template/group/update'), config)
})

export const deleteTemplateGroup = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/prompt_template/group/delete'), config)
})

export const addTemplate = defineApi((config, data, imageFile) => {
  const form = new FormData()
  form.append('image', imageFile)
  form.append('item', new Blob([JSON.stringify(data)], { type: 'application/json' }))
  config.data = form
  http.post(path('/prompt_template/item/add'), config)
})

export const updateTemplate = defineApi((config, data, imageFile) => {
  const form = new FormData()
  form.append('image', imageFile)
  form.append('item', new Blob([JSON.stringify(data)], { type: 'application/json' }))
  config.data = form
  http.post(path('/prompt_template/item/update'), config)
})

export const deleteTemplate = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/prompt_template/item/delete'), config)
})

export const listTemplateByGroupId = defineApi((config, groupId) => {
  config.params = { groupId: groupId }
  return http.load(path('/prompt_template/item/list/by_group'), config)
})

export const downloadModel = defineApi((config, url, type, fileName) => {
  config.timeout = 1000 * 60 * 10
  config.params = {
    url: url,
    type: type,
    fileName: fileName
  }
  http.post(path('/prompt_template/model/download'), config)
})

export const getDownloadModelProgress = defineApi((config, url) => {
  http.get(path('/prompt_template/model/download/progress'), config)
})
